import {
  Button,
  InputAdornment,
  Pagination,
  PaginationItem,
  Stack,
  TextField,
} from "@mui/material";
import GridLoadingScreen from "components/GridLoadingScreen";
import SearchIcon from "icons/SearchIcon";
import debounce from "lodash/debounce";
import NotificationSearch from "pages/dashboards/NotificationSearch";
import { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { GrFormClose } from "react-icons/gr";
import { useNavigate } from "react-router-dom";
import { useRecoilState, useSetRecoilState } from "recoil";
import { API } from "service/api";
import { selectCatID, selectCatLevel } from "store/products/category";
import {
  productAtom,
  productsLoading,
  searchProductsList,
  showProductAddModal,
} from "store/products/products";
import Category from "../Category/Category";
import ProductAdd from "../ProductAdd/ProductAdd";
import ProductItems from "../ProductItems/ProductItems";
import SearchProduct from "../SearchInput/SearchProducts";
import style from "./Products.module.css";
export default function Product() {
  //get user id from local storage
  const userJSON = localStorage.getItem("user");
  const user = userJSON ? JSON.parse(userJSON) : null;
  const [loading, setLoading] = useState(false);
  const [category, setCategory] = useState<Array<any>>([]);
  const [productArray, setProductsArray] = useState<Array<any>>([]);
  const [selectedCat, setSelectdCat] = useState("");
  const [selectedCatLevel, setSelectdCatLevel] = useState("");
  const [open, setOpen] = useState(false);
  const [pagination, setPagination] = useState(0); //for product pagination
  const [page, setPage] = useState(1); //for product pagination
  const setEditProductData = useSetRecoilState(productAtom);
  const [selectedCatId, setSelectdCatId] = useRecoilState(selectCatID);
  const [prodLoading, setProdLoading] = useRecoilState(productsLoading);
  const navigate = useNavigate();
  const [selectedCatLevelRecoil, setSelectdCatLevelRecoil] =
    useRecoilState(selectCatLevel);

  const setShowProductModal = useSetRecoilState(showProductAddModal);
  const [searchProducts, setSearchProducts] =
    useRecoilState(searchProductsList);

  //search products
  const [showProductSearchPage, setShowProductSearchPage] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  // const [searchProducts, setSearchProducts] = useState([]);
  const [pageCount, setPageCount] = useState(1);
  const [pageNo, setPageNo] = useState(1);

  //getting Seller Category
  const getSellerCategoryTree = async (sellerId: String) => {
    try {
      setLoading(true);
      const res: any = await API.getData(
        `${API.sellerUrl}getsellercategorytree/${sellerId}`
      );
      setCategory(res?.data);
      if (
        res?.data?.length > 0 &&
        res?.data[0]?.sub?.length > 0 &&
        res?.data[0].sub[0]?.sub?.length > 0
      ) {
        setSelectdCatLevelRecoil(3);
        setSelectdCatId(res?.data[0]?.sub[0]?.sub[0]?._id);
      } else {
        setSelectdCatLevelRecoil(2);
        setSelectdCatId(res?.data[0]?.sub[0]?._id);
      }
      setLoading(false);
    } catch (e) {
      setLoading(false);
      toast.error("Error in Fetching Categories");
    }
  };

  useEffect(() => {
    getSellerCategoryTree(user._id);
  }, []);

  const getAllProducts = async () => {
    try {
      setProdLoading(true);
      
      const res = await API.getData(
        API.sellerUrl +
          `product/category/${+selectedCatLevelRecoil}/${selectedCatId}?limit=10&page=${page}`
      );
      
      setProductsArray(res?.data?.data);
      setPagination(Math.ceil(res?.data?.total / 10));
      setProdLoading(false);
    } catch (error) {
      console.error("Error in Fetching Products");
      setProdLoading(false);
    }
  };

  useEffect(() => {
    getAllProducts();
  }, [selectedCat, page, selectedCatId, selectedCatLevelRecoil]);


  const handleSearch = () => {
    API.getData(
      API.sellerUrl +
        `search/product?search=${searchValue}&limit=10&page=${pageNo}&sort=price&order=asc`
    )
      .then((res) => {
        setSearchProducts(res?.data?.data);
        setPageCount(Math.ceil(res?.data?.total / 10));
        // console.log(res);
      })
      .catch((err) => console.log(err));
  };
  // Debounce the handleSearch function
  const debouncedSearch = debounce(handleSearch, 300);
  useEffect(() => {
    debouncedSearch();
    return () => {
      debouncedSearch.cancel();
    };
  }, [searchValue, pageNo]);

  function getWithExpiry(key?: any) {
    const itemStr = localStorage.getItem(key);

  
    // If the item doesn't exist, return null
    if (!itemStr) {
      return null;
    }
  
    const item = JSON.parse(itemStr);
    const now = new Date();
  
    // Compare the expiry time of the item with the current time
    if (now.getTime() > item.expiry) {
      // If the item is expired, remove it from localStorage and return null
      localStorage.removeItem(key);
      return null;
    }
    return item.value;
  }


  useEffect(() => {
     const result =  getWithExpiry('pin');
     if (!result) {
        navigate('/dashboard');
     }
  }, []);

  return (
    <>
      {!loading ? (
        <>
          <div className={style.head}>
            <h1>Products</h1>
            <NotificationSearch />
          </div>

          <div className={style.option}>
            <div style={{ flex: 1.5 }}>
              <TextField
                value={searchValue}
                onChange={(e) => {
                  setSearchValue(e.target.value);
                  setPageNo(1);
                }}
                onKeyDown={(e) => {
                  if (
                    searchValue.length === 0 &&
                    (e.key === "Backspace" || e.key === "Delete")
                  ) {
                    setShowProductSearchPage(false);
                  }
                }}
                label='Search Products'
                sx={{ width: "50%", backgroundColor: "white" }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end'>
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
                onFocus={() => setShowProductSearchPage(true)}
                // onBlur={() => setShowProductSearchPage(false)}
              />
            </div>
            <div className={style.btnContainer}>
              <Button onClick={() => navigate("/dashboard/user-products-add")}>
                Excel Upload
              </Button>
              <Button
                onClick={(e) => {
                  e.stopPropagation();
                  setShowProductModal(true);
                  setEditProductData({});
                }}
              >
                Add Product
              </Button>
            </div>
          </div>
          {showProductSearchPage ? (
            <div className={style.body}>
              <div className={style.productSearchContainer}>
                <header>
                  <h3>Search Products</h3>

                  <GrFormClose
                    onClick={() => {
                      setShowProductSearchPage(false);
                      setSearchValue("");
                      setPageNo(1);
                    }}
                    className={style.cross}
                  />
                </header>
                <div style={{ backgroundColor: "#f3f4f9" }}>
                  <SearchProduct
                    pageCount={pageCount}
                    setPageNo={setPageNo}
                    pageNo={pageNo}
                  />
                </div>
                {searchProducts && searchProducts?.length > 0 && (
                  <Stack
                    className={style.footer}
                    spacing={2}
                    sx={{
                      float: "right",
                      paddingBottom: "1rem",
                      marginTop: "1rem",
                    }}
                  >
                    <Pagination
                      style={{
                        display: "flex",
                        justifyContent: "center",
                      }}
                      size='small'
                      count={pageCount}
                      color='primary'
                      page={pageNo}
                      onChange={(e: any, value: any) => {
                        e.stopPropagation();
                        setPageNo(value);
                      }}
                      renderItem={(item: any) => (
                        <PaginationItem
                          {...item}
                          sx={{
                            ...item.sx,
                            fontWeight: "bolder",
                          }}
                        />
                      )}
                    />
                  </Stack>
                )}
              </div>
            </div>
          ) : (
            <div className={style.body}>
              <div className={style.categoryContainer}>
                <header>
                  <h3>Category</h3>
                </header>
                <Category
                  category={category}
                  setCategory={setCategory}
                  getSellerCategoryTree={getSellerCategoryTree}
                  getAllProducts={getAllProducts}
                  setSelectdCat={setSelectdCat}
                  setPage={setPage}
                  setSelectdCatLevel={setSelectdCatLevel}
                  setPagination={setPagination}
                />
              </div>
              <div className={style.productContainer}>
                <header>
                  <h3>Products</h3>
                </header>
                <ProductItems
                  productsArray={productArray}
                  pagination={pagination}
                  page={page}
                  setPage={setPage}
                  category={category}
                />
                {productArray?.length > 0 && (
                  <Stack
                    className={style.footer}
                    spacing={2}
                    sx={{
                      float: "right",
                      paddingBottom: "1rem",
                      marginTop: "1rem",
                    }}
                  >
                    <Pagination
                      style={{
                        display: "flex",
                        justifyContent: "center",
                      }}
                      size='small'
                      count={pagination}
                      color='primary'
                      page={page}
                      onChange={(e: any, value: any) => {
                        e.stopPropagation();
                        setPage(value);
                      }}
                      renderItem={(item: any) => (
                        <PaginationItem
                          {...item}
                          sx={{
                            ...item.sx,
                            fontWeight: "bolder",
                          }}
                        />
                      )}
                    />
                  </Stack>
                )}
              </div>
            </div>
          )}
        </>
      ) : (
        <GridLoadingScreen className={style.loader} />
      )}
      <div style={{ display: "none" }}>
        <ProductAdd />
        {/* <ProductAddModal setOpen={setOpen} open={open} /> */}
      </div>
    </>
  );
}
