import { SvgIcon, SvgIconProps } from "@mui/material";
import PermIdentityTwoToneIcon from '@mui/icons-material/PermIdentityTwoTone';
import React from "react";

const UserProfile = (props: SvgIconProps) => {
  return (
    <SvgIcon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
        <PermIdentityTwoToneIcon />
    </SvgIcon>
  );
};

export default UserProfile;
