import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  Card,
  Checkbox,
  Divider,
  FormControlLabel,
  FormHelperText,
} from "@mui/material";
import FlexBox from "components/FlexBox";
import LightTextField from "components/LightTextField";
import { H1, H3, Small } from "components/Typography";
import {
  SocialIconButton,
  TextFieldWrapper,
} from "components/authentication/StyledComponents";
import { useFormik } from "formik";
import useAuth from "hooks/useAuth";
import FacebookIcon from "icons/FacebookIcon";
import GoogleIcon from "icons/GoogleIcon";
import { FC, FormEvent, useEffect, useState } from "react";
import toast from "react-hot-toast";
import { Link, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { API } from "../../service/api";
import RiderImage from "./dashboard.png";
const TimingButton = (props: any) => {
  let { contact } = props?.contact;
  const [resendDisable, setResendDisable] = useState(true);
  const [seconds, setSeconds] = useState(30);
  const [timerVisible, setTimerVisible] = useState(true);

  useEffect(() => {
    if (seconds === 0) {
      setResendDisable(false);
      setTimerVisible(false);
    }
    if (seconds > 0) {
      const interval = setInterval(() => {
        if (seconds > 0) {
          setSeconds((seconds) => seconds - 1);
        } else {
        }
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [seconds]);
  const convertSecond = (seconds: any) => {
    if (seconds < 10) {
      return (seconds = `00:0${seconds}`);
    } else {
      return `00:${seconds}`;
    }
  };
  const resendOTP = () => {
    setResendDisable(true);

    setTimeout(() => {
      setResendDisable(false);
    }, 36000);

    setSeconds(30);
    setTimerVisible(true);

    API.postData(API.authUrl + "otp/send", { contact: contact })
      .then((result) => { })
      .catch((err) => {
        setResendDisable(true);
        setTimerVisible(true);
        if (err.error) {
          console.log(err);
        }
      });
  };

  return (
    <button
      style={{
        padding: "10px",
        marginTop: "20px",
        // borderRadius: "10px",
        border: "1px solid grey",
        backgroundColor: resendDisable ? "white" : "rgba(255, 232, 2, 1)",
        color: !resendDisable ? "white" : "black",
        fontWeight: "500",
        fontSize: resendDisable ? 10 : 15,
        width: 130,
        height: 40,
        marginLeft: 10,
        boxShadow: "revert-layer",
      }}
      disabled={resendDisable}
      onClick={() => resendOTP()}
    >
      {"Resend OTP" + (timerVisible ? " " + convertSecond(seconds) : "")}
    </button>
  );
};
const Register: FC = () => {
  const { register } = useAuth();
  const { login } = useAuth();

  const navigate = useNavigate();
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [checkOtp, setCheckOtp] = useState(false);
  const [input, setInput] = useState({ contact: "", otp: "" });
  const [otpSend, setOtpSend] = useState(false);
  const initialValues = {
    name: "",
    email: "",
    password: "",
    terms: true,
    submit: null,
  };
  // form field value validation schema
  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    email: Yup.string()
      .email("Must be a valid email")
      .max(255)
      .required("Email is required"),
    password: Yup.string()
      .min(6, "Password should be of minimum 6 characters length")
      .required("Password is required"),
  });

  // const { errors, values, touched, handleBlur, handleChange, handleSubmit } =
  //   useFormik({
  //     initialValues,
  //     validationSchema,
  //     onSubmit: async (values: any) => {
  //       setLoading(true);
  //       try {
  //         await register(values.email, values.password, values.name);
  //         setLoading(false);
  //         toast.success("You registered successfully");
  //         navigate("/dashboard");
  //       } catch (error: any) {
  //         setError(error?.message);
  //         setLoading(false);
  //       }
  //     },
  //   });

  const handlePhone = (e: FormEvent<HTMLFormElement>) => {
    if (input.contact.length !== 10) {
      setError("Invalid phone number");
    } else {
      const contactSend = { contact: input.contact };
      API.postData(API.authUrl + "otp/registered-seller", contactSend)
        .then((result) => {
          setOtpSend(true);
        })
        .catch((err) => {
          navigate("/unauthorised");
          alert(err);
        });
    }
    e.preventDefault();
  };
  const [token, setToken] = useState(null);

  const handlePhoneVerify = async (e: any) => {
    setError("");
    if (input.otp.length < 6) {
      setError("Invalid Otp number");
    } else {
      setLoading(true);
      login(input)
        .then(() => {
          setLoading(false);
          toast.success("You Logged In Successfully test");
          navigate("/dashboard");
        })
        .catch((error) => {
          setError(error.message);
          setLoading(false);
        });
    }
    e.preventDefault();
  };
  const { errors, values, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues,
      validationSchema,
      onSubmit: (values: any) => {
        setLoading(true);
        login(input)
          .then(() => {
            setLoading(false);
            toast.success("You Logged In Successfully test");
            navigate("/dashboard");
          })
          .catch((error) => {
            setError(error.message);

            setLoading(false);
          });
      },
    });

  return (
    <FlexBox
      sx={{
        alignItems: "center",
        flexDirection: "column",
        justifyContent: "center",
        height: { sm: "100%" },
        backgroundImage: `url(${RiderImage})`,
        backgroundPosition: "center",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        width: "100%",
        backgroundColor: "#000000",
      }}
    >
      <Card sx={{ padding: 4, maxWidth: 600, boxShadow: 1, marginLeft: "55%" }}>
        <FlexBox
          alignItems="center"
          flexDirection="column"
          justifyContent="center"
          mb={5}
        >
          <Box width={100} mb={1}>
            <img src="/static/logo/logo.png" width="100%" alt="Uko Logo" />
          </Box>
          <H1 fontSize={24} fontWeight={700}>
            Welcome To Fairdeal
          </H1>
        </FlexBox>
        {!checkOtp ? (
          !otpSend ? (
            <Card sx={{ padding: 4, maxWidth: 600, boxShadow: 1 }}>
              <FlexBox
                alignItems="center"
                flexDirection="row"
                justifyContent="center"
                mb={5}
              >
                <form onSubmit={(e) => handlePhone(e)}>
                  <Box
                    style={{
                      padding: "10px",
                      borderRadius: "10px",
                      border: "1px solid grey",
                    }}
                  >
                    <input
                      type="Number"
                      style={{ border: "0px solid", outline: "none" }}
                      value={input.contact}
                      onChange={(e) =>
                        setInput({ ...input, contact: e.target.value })
                      }
                      placeholder="Enter your mobile number"
                    />
                  </Box>
                  {error && (
                    <p style={{ color: "red", fontSize: "10px" }}>*{error}</p>
                  )}

                  <Button
                    type="submit"
                    sx={{
                      padding: "10px",
                      marginTop: "20px",
                      backgroundColor: "rgba(255, 232, 2, 1)",
                      fontWeight: "500",
                      fontSize: 20,
                      width: 200,
                    }}
                  >
                    Send OTP
                  </Button>
                </form>
              </FlexBox>
            </Card>
          ) : (
            <Card sx={{ padding: 4, maxWidth: 600, boxShadow: 1 }}>
              <FlexBox
                alignItems="center"
                flexDirection="row"
                justifyContent="center"
                mb={5}
              >
                <form onSubmit={(e) => handlePhoneVerify(e)}>
                  <Box
                    style={{
                      padding: "10px",
                      borderRadius: "10px",
                      border: "1px solid grey",
                    }}
                  >
                    <input
                      type="Number"
                      style={{ border: "0px solid", outline: "none" }}
                      value={input.otp}
                      onChange={(e) =>
                        setInput({ ...input, otp: e.target.value })
                      }
                      placeholder="Enter OTP"
                    />
                  </Box>
                  {error && (
                    <p style={{ color: "red", fontSize: "10px" }}>*{error}</p>
                  )}
                  <Button type="submit">Verify OTP</Button>

                  <TimingButton contact={input.contact} />
                </form>
              </FlexBox>
            </Card>
          )
        ) : (
          <FlexBox justifyContent="space-between" flexWrap="wrap" my="1rem">
            <SocialIconButton
              // onClick={loginWithGoogle}
              startIcon={<GoogleIcon sx={{ mr: "0.5rem" }} />}
            >
              Sign up with Google
            </SocialIconButton>
            <SocialIconButton
              // onClick={loginWithFacebook}
              startIcon={<FacebookIcon sx={{ mr: "0.5rem" }} />}
            >
              Sign up with Facebook
            </SocialIconButton>

            <Divider sx={{ my: 3, width: "100%", alignItems: "flex-start" }}>
              <H3 color="text.disabled" px={1}>
                Or
              </H3>
            </Divider>

            <form noValidate onSubmit={handleSubmit} style={{ width: "100%" }}>
              <FlexBox justifyContent="space-between" flexWrap="wrap">
                <TextFieldWrapper>
                  <LightTextField
                    fullWidth
                    name="name"
                    type="text"
                    label="Name"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.name || ""}
                    error={Boolean(touched.name && errors.name)}
                    helperText={touched.name && errors.name}
                  />
                </TextFieldWrapper>

                <TextFieldWrapper>
                  <LightTextField
                    fullWidth
                    name="email"
                    type="email"
                    label="Email"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.email || ""}
                    error={Boolean(touched.email && errors.email)}
                    helperText={touched.email && errors.email}
                  />
                </TextFieldWrapper>
              </FlexBox>

              <TextFieldWrapper sx={{ mt: 2, width: "100%" }}>
                <LightTextField
                  fullWidth
                  name="password"
                  type="password"
                  label="Password"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.password || ""}
                  error={Boolean(touched.password && errors.password)}
                  helperText={touched.password && errors.password}
                />
              </TextFieldWrapper>

              <FormControlLabel
                control={
                  <Checkbox
                    disableRipple
                    checked={values.terms}
                    onChange={handleChange}
                    name="terms"
                  />
                }
                label="I agree to terms & conditions"
                sx={{
                  marginTop: "0.5rem",
                  "& .MuiTypography-root": { fontWeight: 600 },
                }}
              />

              {error && (
                <FormHelperText
                  error
                  sx={{
                    mt: 2,
                    fontSize: 13,
                    fontWeight: 500,
                    textAlign: "center",
                  }}
                >
                  {error}
                </FormHelperText>
              )}

              <Box sx={{ mt: 4 }}>
                {loading ? (
                  <LoadingButton loading fullWidth>
                    Sign Up
                  </LoadingButton>
                ) : (
                  <Button fullWidth type="submit">
                    Sign Up
                  </Button>
                )}
              </Box>
            </form>

            <Small margin="auto" mt={3} color="text.disabled">
              Do you already have an account?{" "}
              <Link to="/login">
                <Small color="primary.main">Log in</Small>
              </Link>
            </Small>
          </FlexBox>
        )}
      </Card>
    </FlexBox>
  );
};

export default Register;
