import { SvgIcon, SvgIconProps } from "@mui/material";
import ShoppingCartTwoToneIcon from '@mui/icons-material/ShoppingCartTwoTone';
import React from "react";

const Orders = (props: SvgIconProps) => {
  return (
    <SvgIcon viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
      <ShoppingCartTwoToneIcon />
    </SvgIcon>
  );
};

export default Orders;