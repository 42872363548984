import { environment } from "../environments/environment";
export class APIUrls {
  readonly baseUrl = environment.apiUrl;
  readonly adminUrl = environment.apiUrl + "admin/";

  // Auth
  readonly authUrl = this.baseUrl + "auth/seller/";

  //Customer
  readonly sellerUrl = this.baseUrl + "customer/seller/";

  // Maintenance
  readonly maintenanceUrl = this.baseUrl + "check-maintenance/";
}
