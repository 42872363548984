import GridLoadingScreen from "components/GridLoadingScreen";
import { useEffect, useState } from "react";
import { FaEdit } from "react-icons/fa";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import {
  productAtom,
  productsList,
  productsLoading,
  showProductAddModal,
} from "store/products/products";
import ProductDisable from "../ProductDisable/ProductDisable";
import style from "./ProductItems.module.css";
export default function ProductItems({
  productsArray,
  pagination,
  setPage,
  page,
  category,
}: any) {
  const [products, setProducts] = useState([]);
  const setShowProductModal = useSetRecoilState(showProductAddModal);
  const [productsRecoil, setProductsRecoil] = useRecoilState(productsList);
  const prodLoading = useRecoilValue(productsLoading);
  useEffect(() => {
    setProducts(productsArray);
    setProductsRecoil(productsArray);
  }, [productsArray]);

  const [editProductData, setEditProductData] = useRecoilState(productAtom);

  return (
    <>
      {!prodLoading ? (
        <>
          <div className={style.box}>
            {productsRecoil.length > 0 ? (
              productsRecoil.map((product: any) => {
                return (
                  <div key={product?._id} className={style.wrapper}>
                    <div className={style.product_container}>
                      <div className={style.image_container}>
                        {/* eslint-disable-next-line jsx-a11y/img-redundant-alt */}
                        <img
                          width='60px'
                          src={
                            product?.thumbImages[0]?.image ||
                            "https://cdn3.iconfinder.com/data/icons/graphic-and-web-design/64/PACKAGING_DESIGN-1024.png"
                          }
                          alt='Product'
                        />
                      </div>
                      <div className={style.product_name}>
                        <p>{product?.name}</p>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            width: "60%",
                          }}
                        >
                          <p>MRP : ₹{product?.minPrice?.price}</p>
                          <p>
                            Selling Price : ₹{product?.minPrice?.sellingPrice}
                          </p>
                        </div>
                      </div>
                    </div>

                    <FaEdit
                      onClick={(e) => {
                        e.stopPropagation();
                        setEditProductData(product);
                        setShowProductModal(true);
                      }}
                      className={style.edit_icon}
                    />

                    <div style={{ float: "right" }}>
                      <ProductDisable key={product._id} product={product} />
                    </div>
                  </div>
                );
              })
            ) : (
              <h3
                style={{
                  textAlign: "center",
                  padding: "1rem",
                  backgroundColor: "#f3f4f9 ",
                }}
              >
                There are no products.
              </h3>
            )}

            {/* <Stack
              spacing={2}
              sx={{
                float: "right",
                paddingBottom: "1rem",
                marginTop: "1rem",
              }}
            >
              <Pagination
                size='small'
                count={pagination}
                color='primary'
                page={page}
                onChange={(e: any, value: any) => {
                  e.stopPropagation();
                  setPage(value);
                }}
                renderItem={(item: any) => (
                  <PaginationItem
                    {...item}
                    sx={{
                      ...item.sx,
                      fontWeight: "bolder",
                    }}
                  />
                )}
              />
            </Stack> */}
          </div>
        </>
      ) : (
        <GridLoadingScreen className={style.loader} />
      )}
    </>
  );
}
