import DeleteIcon from "@mui/icons-material/Delete";
import { Box, Button } from "@mui/material";
import { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import GridLoader from "react-spinners/GridLoader";

//import toast from "react-toastify/dist/react-toastify.esm";

import { API } from "service/api";

const timeIntervals = [
  "00:00",
  "00:30",
  "01:00",
  "01:30",
  "02:00",
  "02:30",
  "03:00",
  "03:30",
  "04:00",
  "04:30",
  "05:00",
  "05:30",
  "06:00",
  "06:30",
  "07:00",
  "07:30",
  "08:00",
  "08:30",
  "09:00",
  "09:30",
  "10:00",
  "10:30",
  "11:00",
  "11:30",
  "12:00",
  "12:30",
  "13:00",
  "13:30",
  "14:00",
  "14:30",
  "15:00",
  "15:30",
  "16:00",
  "16:30",
  "17:00",
  "17:30",
  "18:00",
  "18:30",
  "19:00",
  "19:30",
  "20:00",
  "20:30",
  "21:00",
  "21:30",
  "22:00",
  "22:30",
  "23:00",
  "23:30",
];

export default function SellerShopTime() {
  const [timings, setTimings] = useState<DayTiming[]>([]);
  const [loading, setLoading] = useState(false);
  const [overlapError, setOverlapError] = useState(false);

  interface DayTiming {
    day: string;
    timings: any[]; // You can replace `any` with a more specific type if needed
    isValid: boolean;
  }
  interface TimingsByDay {
    [key: string]: any; // Add an index signature to allow string indexing
  }
  async function getShopTime() {
    setLoading(true);
    const d: DayTiming[] = [
      { day: "sunday", timings: [], isValid: true },
      { day: "monday", timings: [], isValid: true },
      { day: "tuesday", timings: [], isValid: true },
      { day: "wednesday", timings: [], isValid: true },
      { day: "thursday", timings: [], isValid: true },
      { day: "friday", timings: [], isValid: true },
      { day: "saturday", timings: [], isValid: true },
    ];

    let res = await API.getData(API.sellerUrl + "profile/shopTimings");

    if (res?.data) {
      console.log(res.data);
      d.map((item) => {
        item.timings = res.data[item.day].map((time: any) => {
          let startTime = time.startTime.slice(0, 5);
          let endTime = time.endTime.slice(0, 5);
          return { ...time, startTime, endTime };
        });
      });

      setTimings(d);
      setLoading(false);
    } else {
      setTimings(d);
      setLoading(false);
    }
  }

  useEffect(() => {
    getShopTime();
  }, []);

  const handleStartTimeChange = (
    dayIndex: any,
    timingIndex: number,
    event: any
  ) => {
    const { value } = event.target;
    const updatedTimings = [...timings];
    updatedTimings[dayIndex].timings[timingIndex].startTime = value;
    setTimings(updatedTimings);
  };

  const handleEndTimeChange = (
    dayIndex: any,
    timingIndex: number,
    event: any
  ) => {
    const { value } = event.target;
    const updatedTimings = [...timings];
    updatedTimings[dayIndex].timings[timingIndex].endTime = value;
    setTimings(updatedTimings);
  };

  const handleAddTiming = (dayIndex: any) => {
    const updatedTimings = [...timings];
    updatedTimings[dayIndex].timings.push({
      _id: Math.random().toString(),
      startTime: timeIntervals[0],
      endTime: timeIntervals[0],
    });
    setTimings(updatedTimings);
  };

  const handleDeleteTiming = (dayIndex: any, timingIndex: number) => {
    const updatedTimings = [...timings];
    updatedTimings[dayIndex].timings.splice(timingIndex, 1);
    setTimings(updatedTimings);
  };

  const validateTimings = () => {
    for (let i = 0; i < timings.length; i++) {
      const day = timings[i];
      const dayTimings = day.timings;

      for (let j = 0; j < dayTimings.length; j++) {
        const currentTiming = dayTimings[j];
        const currentStartTime = new Date(
          `2000-01-01T${currentTiming.startTime}`
        );
        const currentEndTime = new Date(`2000-01-01T${currentTiming.endTime}`);

        for (let k = 0; k < dayTimings.length; k++) {
          if (k !== j) {
            const compareTiming = dayTimings[k];
            const compareStartTime = new Date(
              `2000-01-01T${compareTiming.startTime}`
            );
            const compareEndTime = new Date(
              `2000-01-01T${compareTiming.endTime}`
            );

            if (
              (currentStartTime >= compareStartTime &&
                currentStartTime < compareEndTime) ||
              (currentEndTime > compareStartTime &&
                currentEndTime <= compareEndTime)
            ) {
              setOverlapError(true);

              toast.error(`Overlap detected for ${day.day}`);
              return false;
            }
          }
        }
      }
    }

    return true;
  };

  const handleSubmit = async () => {
    try {
      if (validateTimings()) {
        // Store timings in objects for each day
        const timingsByDay: TimingsByDay = {};

        timings.forEach((day) => {
          const timingsArray = day.timings.map((timing: any) => ({
            startTime: timing.startTime,
            endTime: timing.endTime,
          }));

          timingsByDay[day.day] = timingsArray;
        });
        ///Called API
        const res = await API.putData(
          API.sellerUrl + "/profile/shopTimings",
          timingsByDay
        );
        if (res.status === "SUCCESS") {
          toast.success("Timings updated successfully");
        }
      }
    } catch (error) {
      console.error(error);
      toast.error("Error updating timings");
    }
  };

  const handleCancel = (day: any) => {
    // Perform cancel logic for the given day
    // console.log(`Cancelled ${day}`);
    // Add your cancellation logic here
  };
  return (
    <>
      {loading ? (
        <GridLoader color='#3692d6' size={25} />
      ) : (
        <div>
          <div style={{ height: "10vh" }}>
            <Button style={{ float: "right" }} onClick={handleSubmit}>
              Submit
            </Button>
          </div>
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "repeat(3, 1fr)",
              gridGap: "3%",
            }}
          >
            {timings.map((day, dayIndex) => (
              <Box
                key={day.day}
                sx={{
                  width: "100%",
                  border: "2px solid rgba(0, 0, 0, 0.1)",
                  borderRadius: "10px",
                  padding: "10px",
                  backgroundColor: "#ffffff",
                  marginBottom: "1%",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <p
                    style={{
                      fontWeight: "600",
                      fontSize: "24px",
                      width: "80%",
                    }}
                  >
                    {day.day.toUpperCase()}
                  </p>
                  {/* <p
                style={
                  {
                    // visibility: !e.edit ? "visible" : "hidden",
                  }
                }
                // onClick={() => handleEdit(day)}
              >
                <EditIcon />
                Edit
              </p> */}
                  {/* <p onClick={() => handleCancel(day.day)}>
                <CancelIcon />
              </p> */}
                </div>
                <div key={day.day}>
                  <p
                    style={{
                      fontWeight: "600",
                      fontSize: "24px",
                      width: "80%",
                    }}
                  >
                    {/* {day.day.toUpperCase()} */}
                  </p>

                  {day.timings.map((timing: any, timingIndex: any) => (
                    <div key={timing._id}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                        }}
                      >
                        <div
                          style={{
                            marginRight: "1rem",
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                          }}
                        >
                          <p style={{ marginRight: "0.5rem" }}>From</p>
                          <select
                            value={timing.startTime}
                            onChange={(event) =>
                              handleStartTimeChange(
                                dayIndex,
                                timingIndex,
                                event
                              )
                            }
                          >
                            {timeIntervals.map((interval) => (
                              <option key={interval} value={interval}>
                                {interval}
                              </option>
                            ))}
                          </select>
                        </div>

                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                          }}
                        >
                          <p style={{ marginRight: "0.5rem" }}>To</p>
                          <select
                            value={timing.endTime}
                            onChange={(event) =>
                              handleEndTimeChange(dayIndex, timingIndex, event)
                            }
                          >
                            {timeIntervals.map((interval) => (
                              <option key={interval} value={interval}>
                                {interval}
                              </option>
                            ))}
                          </select>
                        </div>

                        <div
                          style={{
                            alignItems: "center",
                            cursor: "pointer",
                          }}
                        >
                          <DeleteIcon
                            onClick={() =>
                              handleDeleteTiming(dayIndex, timingIndex)
                            }
                          />
                        </div>
                      </div>
                    </div>
                  ))}
                  <br />
                  <div>
                    <p
                      style={{ cursor: "pointer" }}
                      onClick={() => handleAddTiming(dayIndex)}
                    >
                      + Add Slot
                    </p>
                  </div>
                </div>
              </Box>
            ))}
          </div>
        </div>
      )}
    </>
  );
}
