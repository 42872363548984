import AuthContext, { AuthState } from "contexts/JWTAuthContext";
import { useContext, useEffect, useRef } from "react";
import toast from "react-hot-toast";
import { io } from "socket.io-client";
import Swal from "sweetalert2";
import config from "../environments/config";

export default function SocketNotification({
  stopNotifySound,
  setStopNotifySound,
}: any) {
  const token = localStorage.getItem("accessToken");
  const { user }: AuthState = useContext(AuthContext);
  const audioRef = useRef<HTMLAudioElement | null>(null);
  const failAudioRef = useRef<HTMLAudioElement | null>(null);
  const handlePlay = () => {
    if (audioRef.current) {
      audioRef.current.play();
    }
  };
  const handlePlayFail = () => {
    if (failAudioRef.current) {
      failAudioRef.current.play();
    }
  };

  useEffect(() => {
    // Create the socket connection
    const socket = io(config.socketUrl, {
      reconnection: true,
      reconnectionDelay: 100,
      auth: {
        type: "seller",
        userId: user?._id,
        session: token,
      },
    });

    socket.on("newSellerNotification", (data) => {
      if (data) {
        toast.success(data.message || data);
      }

      if (data.type === "SELLER_SINGLE_ORDER_PLACED") {
        Swal.fire({
          title: "New Order",
          text: data.message,
          icon: "success",
          confirmButtonText: "OK",
          confirmButtonColor: "blue",
          allowOutsideClick: false,
        }).then((result) => {
          if (result.isConfirmed) {
            if (audioRef.current) {
              audioRef.current.pause();
            }
          }
        });
        handlePlay();
      }
      if (
        data?.type === "SELLER_ORDER_CANCELLED" ||
        data?.type === "SELLER_ORDER_CANCELLED_RIDER"
      ) {
        Swal.fire({
          title: "Order Cancelled",
          text: data.message,
          icon: "error",
          confirmButtonText: "OK",
          confirmButtonColor: "blue",
          allowOutsideClick: false,
        }).then((result) => {
          if (result.isConfirmed) {
            if (failAudioRef.current) {
              failAudioRef.current.pause();
            }
          }
        });
        handlePlayFail();
      }
    });

    // Clean up the socket connection on component unmount
    return () => {
      socket.disconnect();
    };
  }, [user?._id, token, audioRef]);

  return (
    <>
      <audio ref={audioRef} loop>
        <source
          src='https://cdn.pixabay.com/audio/2022/10/04/audio_79bd7a4d75.mp3'
          type='audio/mpeg'
        />
      </audio>
      <audio ref={failAudioRef} loop>
        <source
          src='https://cdn.pixabay.com/audio/2023/07/24/audio_4385e216e9.mp3'
          type='audio/mpeg'
        />
      </audio>
    </>
  );
}
