import { Store } from "pullstate";

interface IAuthStore {
  auth: boolean;
  authState: any;
  token: any;
  requiredLogin: boolean;
  newUser: boolean;
  userType: any;
  approved: boolean;
  kyc: boolean;
  available?: boolean;
}

export const AuthStore = new Store<IAuthStore>({
  auth: false,
  authState: null,
  token: null,
  requiredLogin: false,
  newUser: true,
  userType: "seller",
  approved: false,
  kyc: false,
});
