import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import {
  default as ArrowDropDownTwoToneIcon,
  default as ArrowDropUpTwoToneIcon,
} from "@mui/icons-material/ArrowDropDownTwoTone";
import CallIcon from "@mui/icons-material/Call";
import CloseTwoToneIcon from "@mui/icons-material/CloseTwoTone";
import DoneTwoToneIcon from "@mui/icons-material/DoneTwoTone";
import RemoveOutlinedIcon from "@mui/icons-material/RemoveOutlined";
// import IndeterminateCheckBoxOutlinedIcon from "@mui/icons-material/IndeterminateCheckBoxOutlined";
import LocationOnIcon from "@mui/icons-material/LocationOn";
// import PhoneInTalkIcon from "@mui/icons-material/PhoneInTalk";
import { Card, CardMedia, Typography } from "@mui/material";
import { default as Box, default as Divider } from "@mui/material/Box";
import Button from "@mui/material/Button";
import Chip from "@mui/material/Chip";
import Collapse from "@mui/material/Collapse";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import Rating from "@mui/material/Rating";
import Stack from "@mui/material/Stack";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import { makeStyles } from "@mui/styles";
import FlexBox from "components/FlexBox";
import { H6, Tiny } from "components/Typography";
import * as React from "react";
import { useContext, useEffect, useMemo, useState } from "react";
import toast from "react-hot-toast";
// import { openPhone } from "../../../helpers/HandleLinks";
import { formatRupee } from "components/helper/number";
import { API } from "../../service/api";
// import { count } from "console";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  TextField,
} from "@mui/material";
import axios from "axios";
import bcrypt from "bcryptjs";
import AuthContext, { AuthState } from "contexts/JWTAuthContext";
import { io } from "socket.io-client";
import Swal from "sweetalert2";
import config from "../../environments/config";
// import config from "environments/config";
import { ORDER_STATUSES } from "utils/vinculum";
import DateRangePicker from "./DateRangePicker"; // Assume you have this component ready
import style from "./OrderTable.module.css";

// component props interface
const useStyles = makeStyles({
  topRow: {
    backgroundColor: "info",
    // // borderRadius: "10rem",
  },
  tableRow: {
    borderRadius: "100px",
  },
  box: {
    width: "100%",
    height: "15rem",
    overflowY: "scroll",
  },
  test: {
    height: "16rem",
    width: "16rem",
    overflow: "hidden",
    backgroundColor: "yellow",
  },
  row: {
    backgroundColor: "info",
    height: "1.5rem",
    borderRadius: "20px",
    marginLeft: "3rem",
    marginBottom: "5rem",
  },
  trow: {
    marginBottom: "2rem",
  },
  tyrow: {
    height: "40px",
    backgroundImage: "linear-gradient(to right, #3366ff, #f7f3d1)",
    padding: "5px",
    marginLeft: "-28px",
    marginRight: "-28px",
  },
  IdWidth: {
    height: "3.5rem",
    padding: "0rem 0.8rem 0rem 0.8rem",
    fontWeight: "600",
    width: "10px",
  },
  IdWidthy: {
    height: "3.5rem",
    cursor: "pointer",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    paddingLeft: "0px",
    marginLeft: "-10px",
  },
  IdWidthx: {
    // height: "0.15rem",
    cursor: "pointer",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    paddingLeft: "0px",
    // marginTop: "5px"
  },
  total: {
    marginRight: "1rem",
    marginLeft: "1rem",
  },
  edgex: {
    width: "50%",
    marginRight: "0rem",
    // backgroundImage: "linear-gradient(to right, #3366ff, #f7f3d1)",
  },
  edgey: {
    backgroundColor: "#ffffff",
    // border: '2px solid rgba(0, 0, 0, 0.1)',
    marginBottom: "0%",
    // boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    borderRadius: "20px",
  },
  boxColor: {
    backgroundImage: "linear-gradient(to right, #3366ff, #f7f3d1)",
    borderRadius: "20px",
    width: "100%",
  },
});

interface Orders {
  accepted: { date: string };
  couponDeduction: any;
  couponProvidedBy: string;
  commission: any;
  getData: any;
  items: any;
  _id: string;
  buyerDetails: any;
  seller: any;
  createdAt: any;
  paymentMode: any;
  deliveryMode: any;
  currentStatus: any;
  type: any;
  order: any;
  value: any;
  item: any;
  rider?: any;
  moneyCollected?: any;
  pins?: any;
  rewardUse?: any;
  isInvoicePrinted?: boolean;
  invoicePrintedDate?: any;
  deliveryFee?: any;
  platformFee?: any;
  order_status?: any;
  bill?: any;
}
interface ICashCollectionModalProps {
  open: boolean;
  onClose: () => void;
  onReceive: (receivedAmount: number) => void;
  initialAmount: number;
}

function OrderList(props: Orders) {
  console.log("props", props);
  const rider = props?.rider?.name;
  const classes = useStyles();
  const type = props?.type;
  const getData = props?.getData;
  const [open, setOpen] = useState(false);
  const [rejectReason, setRejectReason] = useState("");
  const [order, setOrder] = useState(
    props.items.map((item: any) => {
      return { ...item, availableQty: item.quantity, status: "Accepted" };
    })
  );
  const [isInvoicePrinted, setIsInvoicePrinted] = useState<any>(false);

  const [deliverMode, setDeliverMode] = useState(props.deliveryMode.value);

  const itemsWithQuantity = props.items.filter(
    (i: any) => i.availableQty && i.accepted
  );
  const [acceptedItems, setAcceptedItems] = useState(
    order.filter((i: any) => i.status === "Accepted")
  );
  const [rejectedItems, setRejectedItems] = useState(
    order.filter((i: any) => i.status === "Rejected")
  );

  const [popup, setPopup] = useState(false);
  const [stat, setStatus] = useState("");
  const [packingtime, setPackingtime] = useState(5);
  const [total, setTotal] = useState(1);

  const rejectItem = (id: any) => {
    const order = acceptedItems.find((o: any) => o.product === id);
    order.status = "Rejected";
    setAcceptedItems(acceptedItems.filter((o: any) => o.product !== id));
    setRejectedItems([...rejectedItems, order]);
  };

  const acceptItem = (id: any) => {
    const order = rejectedItems.find((o: any) => o.product === id);
    order.status = "Accepted";
    setRejectedItems(rejectedItems.filter((o: any) => o.product !== id));
    setAcceptedItems([...acceptedItems, order]);
  };

  function setAvailableQty(index: number, qty: number) {
    let newItems = [...acceptedItems];
    newItems[index].availableQty = qty;
    setAcceptedItems(newItems);
  }

  const approvalAlert = (name: any) => {
    setStatus(name);
    setTimeout(() => {
      setPopup(true);
    }, 1000);
  };

  const handleClose = () => {
    setPopup(false);
    setRejectReason("");
  };

  // export const openPhone = (phoneNumber) => {
  //   Linking.openURL(`tel:${phoneNumber}`);
  // };

  // console.log(props, "props");

  const confirm = async () => {
    // setLoading(true);
    const orderData = JSON.parse(JSON.stringify(props));
    orderData.items = acceptedItems;
    orderData.rejectedItems = rejectedItems;

    if (stat === "reject") {
      const data = [
        { _id: props._id, amount: 0, period: 1, remarks: rejectReason },
      ];
      API.postData(API.sellerUrl + "orders/reject", data)
        .then((res) => {
          toast.success(`Order  rejected successfully.`);
          setPopup(false);
          setOpen(false);
          getData();
        })
        .catch((error) => {
          console.error(error);
          toast.error(
            `Cannot
            "reject" the order. Try again...`
          );
        });
    } else {
      const data = {
        order: props._id,
        packingTime: packingtime,
        deliveryMode: deliverMode,
        initialDeliveryMode: props.deliveryMode.value,
        acceptedItems: acceptedItems.map((i: any) => ({
          product: {
            _id: i.product._id,
            itemType: i.itemType,
            itemSet: i.itemSet._id,
            variant: i.variant._id,
          },
          availableQty: i.availableQty,
        })),
        rejectedItems: rejectedItems.map((i: any) => ({
          product: i.product._id,
          itemType: i.itemType,
          itemSet: i.itemSet._id,
          variant: i.variant._id,
        })),
        itemsWithQuantity: itemsWithQuantity.map((i: any) => ({
          product: i.product._id,
          itemType: i.itemType,
          itemSet: i.itemSet._id,
          variant: i.variant._id,
          availableQty: i.availableQty,
        })),
      };
      // setLoading(false);
      API.putData(API.sellerUrl + "orders/turn-in/" + props._id, data)
        .then(async (res) => {
          toast.success(
            `Order ${
              acceptedItems.length ? "accepted" : "rejected"
            } successfully.`
          );
          if (rejectedItems.length) {
            await confirmRejectedItemDisable(rejectedItems);
          }
          setPopup(false);
          setOpen(false);
          getData();
        })
        .catch((error) => {
          console.error(error);
          toast.error(
            `Cannot ${
              acceptedItems.length ? "turn in" : "reject"
            } the order. Try again.`
          );
        });
    }
  };

  const confirmRejectedItemDisable = async (rejectedItems: any) => {
    // Extract the names of the rejected items
    const rejectedItemNames = rejectedItems
      .map(
        (item: any, index: number) =>
          `<span style="font-weight: bold;">${index + 1}. ${item.name}</span>`
      )
      .join("<br>");

    Swal.fire({
      title: `Rejected ${rejectedItems.length} Item`,
      html: `Do you want to disable the Rejected Items till tomorrow?<br><br><div style="text-align: left;">${rejectedItemNames}</div>`,
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Yes",
      confirmButtonColor: "blue",
      cancelButtonText: "No",
      cancelButtonColor: "red",
      allowOutsideClick: false,
    }).then(async (result) => {
      if (result.isConfirmed) {
        await disableProductsTillTomorrow(rejectedItems);
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        return;
      }
    });
  };

  const disableProductsTillTomorrow = async (rejectedItems: any) => {
    // setLoading(true);
    const products = rejectedItems.map((item: any) => item.product._id);
    let singleDate = new Date();
    singleDate.setDate(singleDate.getDate() + 1);
    const data = {
      products,
      endDateTime: singleDate,
      durationType: "tomorrow",
    };

    API.postData(API.sellerUrl + "rejected/products/manualschedule", data)
      .then((res) => {
        toast.success(`Items disabled successfully.`);

        // setLoading(false);
      })
      .catch((error) => {
        toast.error(`Cannot disable items. Try again later`);
        // setLoading(false);
      });
  };

  const readyResult = (data: any) => {
    // setShowReady(false);
    if (data) {
      const orderId = data[0]._id;
      API.putData(API.sellerUrl + `orders/${orderId}/status/ready`, {})
        .then((res) => {
          toast.success("Order ready!");
          setPopup(false);
          setOpen(false);
          getData();
        })
        .catch((error) => {
          console.error(error);
          toast.error("Cannot dispatch order. try again.");
        });
    }
  };
  const openPhone = (phoneNumber: any) => {
    window.location.href = `tel:${phoneNumber}`;
  };
  const openMap = (coordinates: any) => {
    const lat = coordinates[1];
    const lng = coordinates[0];
    const url = `https://www.google.com/maps/search/?api=1&query=${lat},${lng}`;

    window.open(url, "_blank");
  };
  interface OrderData {
    name: string;
    value: number;
  }

  const orderRejectOption = [
    {
      id: 1,
      reason: "Items are out of stock",
    },
    {
      id: 2,
      reason: "Items price are not correct",
    },
    {
      id: 3,
      reason: "Shop closed",
    },
  ];

  const printPicklistDocument = () => {
    // Generate item list HTML
    const itemListHtml = acceptedItems
      .map(
        (item: any, index: number) =>
          `<tr>
          <td>${item.name}</td>
          <td>${item?.product?.minPrice?.price}
          <td>${item.quantity}</td>
       </tr>`
      )
      .join("");

    const picklistHtml = `<html>
        <head>
            <title>Picklist</title>
            <style>
              table {
                width: 100%;
                border-collapse: collapse;
              }
              th, td {
                border: 1px solid black;
                padding: 8px;
                text-align: left;
              }
              th {
                background-color: #f2f2f2;
              }
            </style>
        </head>
        <body>
            <h1>Picklist for Order</h1>
            <p>Order ID: ${props._id}</p>
            <table>
                <thead>
                    <tr>
                        <th>Item Name</th>
                        <th>MRP</th>
                        <th>Quantity</th>
                    </tr>
                </thead>
                <tbody>
                    ${itemListHtml}
                </tbody>
            </table>
            <p>Please print this document and close this window when done.</p>
        </body>
    </html>`;

    const printWindow = window.open("", "_blank");
    if (printWindow) {
      printWindow.document.write(picklistHtml);
      printWindow.document.close();
      printWindow.focus();
      printWindow.print();
      // Consider user manually closing the window or set a delay
    } else {
      toast.error(
        "Failed to open the print window. Please allow pop-ups for this site."
      );
    }
  };

  function generateAndOpenInvoice() {
    // const order = props;
    // Generate item list HTML
    const itemListHtml = acceptedItems
      .map(
        (item: any, index: number) =>
          `<tr>
         <td>${index + 1}</td>
         <td>${item.name}</td>
         <td>${item?.product?.minPrice?.price}</td>
         <td>${item.unitPrice.toFixed(2)}</td>
         <td>${item.quantity}</td>
         <td>${item.amounts.main.toFixed(2)}</td>
         <td>${(item?.amounts?.discount || 0)?.toFixed(2)}</td>
         <td>${
           item.gst === 28
             ? ((item.amounts.total * 100) / 140).toFixed(2)
             : item.amounts.net.toFixed(2)
         }</td>
         <td>${item.gst === 28 ? `${item.gst}%+12%` : `${item.gst}%+0`}</td>
         <td>${
           item.gst === 28
             ? (
                 parseFloat(((item.amounts.total * 100) / 140).toFixed(2)) *
                 0.28
               ).toFixed(2)
             : item.amounts.gst.toFixed(2)
         }</td>
         <td>${
           item.gst === 28
             ? (
                 parseFloat(((item.amounts.total * 100) / 140).toFixed(2)) *
                 0.12
               ).toFixed(2)
             : `0.00`
         }</td>
         <td>${item.amounts.total.toFixed(2)}</td>
      </tr>`
      )
      .join("");
    // Initialize total variables
    let totalAmount = 0,
      totalGST = 0,
      totalQuantity = 0;

    // Calculate totals from acceptedItems
    acceptedItems.forEach((item: any, index: number) => {
      totalQuantity += item.quantity;
      totalAmount += item.amounts.total;
      totalGST +=
        item.amounts.total - item.amounts.total / (1 + item.gst / 100);
    });

    // Assuming GST is equally split between CGST and SGST
    const cgst = totalGST / 2;
    const sgst = totalGST / 2;

    // Format numbers to two decimal places for display
    const format = (num: number): string => {
      return num.toFixed(2);
    };

    const invoiceHtml = `<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>GST Invoice</title>
        <script src="https://cdn.jsdelivr.net/npm/qrcodejs/qrcode.min.js"></script>
            <script src="https://cdn.jsdelivr.net/npm/jsbarcode@3.11.5/dist/JsBarcode.all.min.js"></script>
    <style>
        body { font-family: 'Helvetica Neue', Arial, sans-serif; margin: 0; padding: 20px; font-size: 14px; background-color: #f9f9f9; }
        h1, h2, h3 { color: #333; }
        .invoice-box { max-width: 90%; margin: auto; padding: 30px; box-shadow: 0 0 10px rgba(0, 0, 0, 0.15); background-color: #fff; border-radius: 5px; }
        .table-header { background-color: #f2f2f2; }
        table { width: 100%; line-height: inherit; text-align: left; border-collapse: collapse; }
        th, td { padding: 12px; border-bottom: 2px solid #eee; }
        .header, .section, .totals { margin-bottom: 20px; }
        .header h1, .totals { margin: 0 0 10px 0; text-align: left; }
        .footer { text-align: center; color: #777; margin-top: 20px; font-size: 12px; }

      
    </style>
</head>
<body>
      <div class="invoice-box">
        <div class="header">
        <div style="display:flex;justify-content: space-between;">
        <div>
            <img src='https://reunite.fairdeal.market/fairdeal_logo.png' alt='Fairdeal Market Logo' style='height:100px'>
</div>
<div>
            <h1>GST Invoice</h1>
            </div>
            <div style='text-align: end;'>
            <p>Invoice No: ${props._id.slice(0, 8)}</p>
            <p>Date: ${new Date().toLocaleDateString()}</p>
            </div>
            </div>
            <h3></h3>
             <div style="display:flex;justify-content: space-between;">
            <div>
            <p><b>FDM Digital Solutions Private Limited</b></p>
            <p>GST No: 06AAECF7932P1Z3</p>
            <p>Address: FIRST FLOOR, 1448, LANDMARK CORPORATE CENTRE, SECTOR 67<br> Manesar, Gurugram, Haryana - 122101</p>
            <p>Mob No: 9311729005</p>
            </div>
            <div id="qrcode"></div>
</div>
             <div style="display:flex;justify-content: space-between;">

        <div id="barcode">
        <img src="https://bwipjs-api.metafloor.com/?bcid=code128&text=${
          props._id
        }&scale=2&height=10&includetext" alt="Barcode" />
      </div>
            <div style='text-align: end;'>
                <h3>Buyer Details</h3>
                 <p>${
                   !!props.buyerDetails?.shopName
                     ? `Shop name: ${props.buyerDetails?.shopName}`
                     : `Buyer name: ${props.buyerDetails.name}`
                 }</p>
                ${
                  props.buyerDetails.gst
                    ? `<p>GST No: ${props.buyerDetails.gst}</p>`
                    : ""
                }
                <p>Address: ${props.buyerDetails.billingAddress.line1} ${
      props.buyerDetails.billingAddress.line2
    }<br>${props.buyerDetails.billingAddress.city}-${
      props.buyerDetails.billingAddress.pincode
    }, ${props.buyerDetails.billingAddress.state}</p>
                <p>Contact No: ${props.buyerDetails.contact}</p>
            </div>
        </div>
         </div>
        <div class="section">
            <table>
                <thead>
                    <tr class="table-header">
                        <th>#</th>
                        <th>Item Name</th>
                        <th>MRP</th>
                        <th>Unit Price</th>
                        <th>Quantity</th>
                        <th>Main</th>
                        <th>Discount</th>
                        <th>Net</th>
                        <th>GST% + Cess%</th>
                        <th>GST Amount</th>
                        <th>Cess Amount</th>
                        <th>Total</th>
                    </tr>
                </thead>
                <tbody>
                    ${itemListHtml}
                </tbody>
            </table>
        </div>
        <h4>Pay By Scanning QR</h4>

             <div class="totals" style="display:flex;justify-content: space-between;">
                    <div id="paymentQr2"></div>
    <div>
            <h3>Totals Breakdown</h3>
            <p>Total Quantity: ${totalQuantity}</p>
            <p>Total Amount Before Discount: ${format(
              props?.bill?.cartValue
            )}</p>
            <p>Coupon Discount: ${props?.bill?.coupon} INR</p>
            <p>Rewards Used Amount: ${props?.bill?.reward}</p>
            <p>Delivery Fee: ${props?.bill?.deliveryFee || 0}</p>
            <p>Platform Fee: ${props?.bill?.platformFee || 0}</p>
            <p>Round Off: ${Math.abs(props?.bill?.roundOff || 0)}</p>
            <p>Total Amount After Discount: ${format(
              props?.bill?.payableAmount
            )} INR</p>
            </div>            
            </div>
               <script>
            var qrcode = new QRCode(document.getElementById("qrcode"), {
                text: "${props._id}",
                width: 128,
                height: 128,
                colorDark : "#000000",
                colorLight : "#ffffff",
                correctLevel : QRCode.CorrectLevel.H
            });
             var paymentQr2 = new QRCode(document.getElementById("paymentQr2"), {
                text: "upi://pay?ver=01&mode=02&orgId=00079&tid=${
                  props._id
                }&tr=${props._id}&tn=${
      props._id
    }&pa=0794281A0093593.bqr@kotak&pn=FDM DIGITAL SOLUTIONS PRIVATE LIMI&mc=5912&am=${format(
      Math.ceil(
        totalAmount -
          props?.rewardUse +
          (props?.deliveryFee || 0) +
          (props?.platformFee || 0)
      )
    )}&mid=0794281A0093593&mtid=1745131&qrMedium=04",
                width: 256,
                height: 256,
                colorDark : "#000000",
                colorLight : "#ffffff",
                correctLevel : QRCode.CorrectLevel.H
            });
        </script>
        <div class="footer">
        <p>Fairdeal.Market</p>
            <p>Thank you for your business!</p>
        </div>
    </div>
<br><br>
     <div class="invoice-box">
        <div class="header">
        <div style="display:flex;justify-content: space-between;">
        <div>
            <img src='https://reunite.fairdeal.market/fairdeal_logo.png' alt='Fairdeal Market Logo' style='height:100px'>
</div>
<div>
            <h1>GST Invoice</h1>
            </div>
            <div style='text-align: end;'>
            <p>Invoice No: ${props._id.slice(0, 8)}</p>
            <p>Date: ${new Date().toLocaleDateString()}</p>
            </div>
            </div>
            <h3></h3>
            <div style="display:flex;justify-content: space-between;">
            <div>
            <p><b>FDM Digital Solutions Private Limited</b></p>
            <p>GST No: 06AAECF7932P1Z3</p>
            <p>Address: FIRST FLOOR, 1448, LANDMARK CORPORATE CENTRE, SECTOR 67<br> Manesar, Gurugram, Haryana - 122101</p>
            <p>Mob No: 9311729005</p>
            </div>
            <div id="qrcode2"></div>
</div>
                <div style="display:flex;justify-content: space-between;">

        <div id="barcode">
        <img src="https://bwipjs-api.metafloor.com/?bcid=code128&text=${
          props._id
        }&scale=2&height=10&includetext" alt="Barcode" />
      </div>

            <div style='text-align: end;'>
                <h3>Buyer Details</h3>
                <p><b>${
                  !!props.buyerDetails?.shopName
                    ? `Shop name: ${props.buyerDetails?.shopName}`
                    : `Buyer name: ${props.buyerDetails.name}`
                }</b></p>
                ${
                  props.buyerDetails.gst
                    ? `<p>GST No: ${props.buyerDetails.gst}</p>`
                    : ""
                }
                <p>Address: ${props.buyerDetails.billingAddress.line1} ${
      props.buyerDetails.billingAddress.line2
    }<br>${props.buyerDetails.billingAddress.city}-${
      props.buyerDetails.billingAddress.pincode
    }, ${props.buyerDetails.billingAddress.state}</p>
                <p>Contact No: ${props.buyerDetails.contact}</p>
            </div>
        </div>
        <div class="section">
            <table>
                <thead>
                    <tr class="table-header">
                        <th>#</th>
                        <th>Item Name</th>
                        <th>MRP</th>
                        <th>Unit Price</th>
                        <th>Quantity</th>
                        <th>Main</th>
                        <th>Discount</th>
                        <th>Net</th>
                        <th>GST% + Cess%</th>
                        <th>GST Amount</th>
                        <th>Cess Amount</th>
                        <th>Total</th>
                    </tr>
                </thead>
                <tbody>
                    ${itemListHtml}
                </tbody>
            </table>
        </div>
        <h4>Pay By Scanning QR</h4>

        <div class="totals" style="display:flex;justify-content: space-between;">
                    <div id="paymentQr"></div>
    <div>
            <h3>Totals Breakdown</h3>
            <p>Total Quantity: ${totalQuantity}</p>
            <p>Total Amount Before Discount: ${format(
              props?.bill?.cartValue
            )}</p>
            <p>Coupon Discount: ${props?.bill?.coupon} INR</p>
            <p>Rewards Used Amount: ${props?.bill?.reward}</p>
            <p>Delivery Fee: ${props?.bill?.deliveryFee || 0}</p>
            <p>Platform Fee: ${props?.bill?.platformFee || 0}</p>
            <p>Round Off: ${Math.abs(props?.bill?.roundOff || 0)}</p>
            <p>Total Amount After Discount: ${format(
              props?.bill?.payableAmount
            )} INR</p>
            </div>            
            </div>
                        <script>
            var qrcode = new QRCode(document.getElementById("qrcode2"), {
                text: "${props._id}",
                width: 128,
                height: 128,
                colorDark : "#000000",
                colorLight : "#ffffff",
                correctLevel : QRCode.CorrectLevel.H
            });
            var paymentQr = new QRCode(document.getElementById("paymentQr"), {
                text: "upi://pay?ver=01&mode=02&orgId=00079&tid=${
                  props._id
                }&tr=${props._id}&tn=${
      props._id
    }&pa=0794281A0093593.bqr@kotak&pn=FDM DIGITAL SOLUTIONS PRIVATE LIMI&mc=5912&am=${format(
      Math.ceil(
        totalAmount -
          props?.rewardUse +
          (props?.deliveryFee || 0) +
          (props?.platformFee || 0)
      )
    )}&mid=0794281A0093593&mtid=1745131&qrMedium=04",
                width: 256,
                height: 256,
                colorDark : "#000000",
                colorLight : "#ffffff",
                correctLevel : QRCode.CorrectLevel.H
            });
        </script>
        <div class="footer">
        <p>Fairdeal.Market</p>
            <p>Thank you for your business!</p>
        </div>
    </div>
</body>
</html>`;

    const printWindow = window.open("", "_blank");
    if (printWindow) {
      printWindow.document.write(invoiceHtml);
      printWindow.document.close();
      // printWindow.focus();
      // printWindow.print();
    } else {
      alert(
        "Failed to open the print window. Please allow pop-ups for this site."
      );
    }
  }

  const amountToCollect = props.order.totalAmt; // as an example

  const [modalOpen, setModalOpen] = useState(false);
  const [totalAmount, setTotalAmount] = useState(0); // Initialize with the default or expected amount
  const [printInvoiceModal, setPrintInvoiceModal] = useState<any>(false);

  const handleOpenModal = (amount: number | null) => {
    if (amount !== null) {
      setTotalAmount(amount); // Set the total amount only if it's not null
    }
    setModalOpen(true); // Open the modal regardless
  };

  const handleCloseModal = () => {
    setModalOpen(false); // Close the modal
  };

  useEffect(() => {
    setIsInvoicePrinted(props.isInvoicePrinted);
  }, [props.isInvoicePrinted]);

  interface PaymentDetails {
    amount: number;
    paymentMethod: string;
    chequeNumber?: string;
    chequeDate?: string;
  }

  interface ReceiveCashParams {
    seller_id: string;
    accepter_id: string;
    paymentDetails: PaymentDetails;
    orderId: string;
    riderId: string;
  }

  const [finAmount, setFinAmount] = useState<number>(0);
  const [finPin, setFinPin] = useState("");
  const [finPaymentMethod, setFinPaymentMethod] = useState("");
  const [finChequeNumber, setFinChequeNumber] = useState("");
  const [finChequeDate, setFinChequeDate] = useState("");

  const handleReceiveClick = async (
    amount: number,
    paymentMethod: string,
    chequeNumber: string,
    chequeDate: string,
    lPin: string
  ) => {
    setFinAmount(amount);
    setFinPaymentMethod(paymentMethod);
    setFinChequeNumber(chequeNumber);
    setFinChequeDate(chequeDate);

    //lPin refers to localPin of CashCollectionModal function
    const pinString = lPin.toString();
    const comparisonPromises = props.pins.map((p: { pin: string; name: any }) =>
      bcrypt.compare(pinString, p.pin).then((result) => ({
        matched: result,
        name: p.name,
      }))
    );
    const results = await Promise.allSettled(comparisonPromises);
    const matchedResult = results.find(
      (result) => result.status === "fulfilled" && result.value.matched
    ) as PromiseFulfilledResult<{ matched: boolean; name: string }> | undefined;

    const newTotalCollected = totalCollected + amount;

    if (!!matchedResult) {
      if (newTotalCollected <= props.order.totalAmt) {
        const accepterName = matchedResult.value.name;
        const paymentDetails = {
          amount,
          paymentMethod,
          chequeNumber: paymentMethod === "Cheque" ? chequeNumber : undefined,
          chequeDate: paymentMethod === "Cheque" ? chequeDate : undefined,
        };

        await handleReceiveCash({
          seller_id: props.seller,
          accepter_id: accepterName,
          paymentDetails,
          orderId: props._id,
          riderId: props.rider?._id,
        });

        toast.success("Cash collected successfully");
        handleCloseModal();
      } else {
        alert("Amount is greater that billed amount");
      }
    } else {
      alert("PIN is incorrect");
    }
  };

  const CashCollectionModal: React.FC<ICashCollectionModalProps> = ({
    open,
    onClose,
  }) => {
    //Used local variables to to define them in main variables
    const [localAmount, setLocalAmount] = useState<number>(0);
    const [localPaymentMethod, setLocalPaymentMethod] = useState("");
    const [localChequeNumber, setLocalChequeNumber] = useState("");
    const [localChequeDate, setLocalChequeDate] = useState("");
    const [localPin, setLocalPin] = useState("");

    return (
      <Modal key='yhagsjah' open={open} onClose={onClose}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
          }}
        >
          <Typography variant='h6' component='h2'>
            Enter the amount received
          </Typography>
          <TextField
            fullWidth
            type='number'
            value={localAmount}
            onChange={(e) => {
              setLocalAmount(parseFloat(e.target.value));
            }}
            onClick={(e) => {
              e.stopPropagation();
            }}
            margin='normal'
            variant='outlined'
            required
          />
          <FormControl fullWidth margin='normal'>
            <InputLabel id='payment-method-label'>Payment Method</InputLabel>
            <Select
              labelId='payment-method-label'
              id='payment-method'
              value={localPaymentMethod}
              label='Payment Method'
              onChange={(e) => setLocalPaymentMethod(e.target.value)}
              onClick={(e) => {
                e.stopPropagation();
              }}
              required
            >
              <MenuItem value='Cash'>Cash</MenuItem>
              <MenuItem value='Company QR'>Company QR</MenuItem>
              <MenuItem value='Rider to Company QR'>
                Rider to Company QR
              </MenuItem>
              <MenuItem value='NEFT/RTGS/IMPS'>NEFT/RTGS/IMPS</MenuItem>
              <MenuItem value='Cheque'>Cheque</MenuItem>
            </Select>
          </FormControl>
          {localPaymentMethod === "Cheque" && (
            <>
              <TextField
                fullWidth
                label='Cheque Number'
                value={localChequeNumber}
                onChange={(e) => setLocalChequeNumber(e.target.value)}
                margin='normal'
                variant='outlined'
              />
              <TextField
                fullWidth
                type='date'
                label='Cheque Date'
                InputLabelProps={{ shrink: true }}
                value={localChequeDate}
                onChange={(e) => setLocalChequeDate(e.target.value)}
                margin='normal'
                variant='outlined'
              />
            </>
          )}
          <Typography variant='h6' component='h2'>
            Acceptor Pin
          </Typography>
          <TextField
            fullWidth
            type='password'
            value={localPin}
            onChange={(e) => setLocalPin(e.target.value)}
            onClick={(e) => {
              e.stopPropagation();
            }}
            margin='normal'
            variant='outlined'
            required
          />
          <Button
            onClick={() => {
              // local variables are passed in following function
              handleReceiveClick(
                localAmount,
                localPaymentMethod,
                localChequeNumber,
                localChequeDate,
                localPin
              );
            }}
            variant='contained'
            color='primary'
          >
            Receive
          </Button>
        </Box>
      </Modal>
    );
  };

  interface IPRINTINVOICEMODALPROPS {
    open: boolean;
    onClose: (e?: any) => void;
    onPrint: (e?: any) => void;
    invoicePrintedDate: any;
  }

  const PrintInvoiceModal: React.FC<IPRINTINVOICEMODALPROPS> = ({
    open,
    onClose,
    onPrint,
    invoicePrintedDate,
  }) => {
    if (!invoicePrintedDate) return <></>;

    return (
      <Modal key='yhagsjah' open={open} onClose={onClose}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "6px",
              marginBottom: "8px",
            }}
          >
            <b>Invoice already printed by user</b>
            <b>
              {`Printed on date ${new Date(
                invoicePrintedDate
              ).toLocaleDateString()} ${new Date(
                invoicePrintedDate
              ).toLocaleTimeString()}`}
            </b>
            <b>Do you want to print again</b>
          </div>
          <div style={{ display: "flex", gap: "12px" }}>
            <Button
              onClick={() => {
                onPrint();
              }}
            >
              Yes
            </Button>
            <Button
              onClick={() => {
                onClose();
              }}
              style={{ background: "white" }}
            >
              No
            </Button>
          </div>
        </Box>
      </Modal>
    );
  };

  interface IHandleReceiveProps {
    orderId: string;
    receivedAmount: number;
    riderId: string;
  }

  interface Rider {
    _id: string;
    name: string;
  }

  interface Orders {
    _id: string;
    order: {
      totalAmt: number;
    };
    rider: Rider;
  }
  const [startDate, setStartDate] = useState(
    new Date().toISOString().split("T")[0]
  );
  const [endDate, setEndDate] = useState(
    new Date().toISOString().split("T")[0]
  );
  const handleReceiveCash = async ({
    seller_id,
    accepter_id,
    paymentDetails,
    orderId,
    riderId,
  }: ReceiveCashParams) => {
    const { amount, paymentMethod, chequeNumber, chequeDate } = paymentDetails;

    const receivedAmtData = {
      seller_id,
      accepter_id,
      orderId,
      receivedAmount: amount,
      riderId,
      paymentMethod,
      chequeNumber,
      chequeDate,
    };

    API.postData(`${API.sellerUrl}orders/updatepayment`, receivedAmtData)
      .then((res) => {
        toast.success(`Collection Collected Successfully.`);
        const url = (type: string) =>
          `${API.sellerUrl}orders/type/${type}?startDate=${startDate}&endDate=${endDate}`;
        getData(null, null, { type: "Delivered" });
      })
      .catch((error) => {
        console.error(error);
        toast.error(
          `Cannot
        "collected" the cash. Try again...`
        );
      });
  };

  const handleAgreeClick = () => {
    confirm();
    setRejectReason("");
    if (stat === "accept") {
      // printPicklistDocument();
    }
  };

  const handleReadyClick = () => {
    if (props.currentStatus?.status === "rider_accepted") {
      readyResult([{ _id: props._id, amount: 0, period: 1 }]);
    } else {
      toast.error("Please wait till the Rider get assigned");
    }
  };

  const verifyInvoicePrint = () => {
    const url = (type: string) =>
      `${API.sellerUrl}orders/verifyPrintInvoice/${props?._id}`;
    API.getData(url(props.type))
      .then((res) => {
        if (res?.data?.isInvoicePrinted) {
          setIsInvoicePrinted(true);
        }
      })
      .catch((err) => {
        console.log(err, "err");
      });
  };

  const totalCollected =
    props?.moneyCollected?.reduce(
      (acc: any, item: any) => acc + item.receivedAmount,
      0
    ) || 0;

  // console.log(totalCollected, "ttt");

  // Calculate the effective amount considering other adjustments
  const effectiveTotalAmount = props?.bill?.payableAmount;

  return (
    <React.Fragment>
      <div>
        <Dialog
          className={style.dialog}
          open={popup}
          onClose={handleClose}
          aria-labelledby='alert-dialog-title'
          aria-describedby='alert-dialog-description'
        >
          <DialogTitle className={style.dialog_title} id='alert-dialog-title'>
            {`Are you sure you want to ${stat} ?`}
          </DialogTitle>
          <br />
          {stat === "reject" ? (
            <div className={style.selectBox}>
              <select
                className={style.select}
                onChange={(e) => setRejectReason(e.target.value)}
              >
                <option className={style.option} value=''>
                  Choose Reason
                </option>
                {orderRejectOption.map((item: any) => {
                  return (
                    <option
                      className={style.option}
                      key={item.id}
                      value={item.reason}
                    >
                      {item.reason}
                    </option>
                  );
                })}
              </select>
            </div>
          ) : (
            ""
          )}
          <DialogActions className={style.dialog_actions}>
            <Button
              className={style.dialog_actions_button_disagree}
              onClick={handleClose}
            >
              Disagree
            </Button>
            <Button
              disabled={stat === "reject" && rejectReason === ""}
              className={style.dialog_actions_button}
              onClick={handleAgreeClick}
              autoFocus
            >
              Agree
            </Button>
          </DialogActions>
        </Dialog>
      </div>
      <TableRow
        className={open ? classes.edgex : classes.edgey}
        sx={{ backgroundColor: "#3366FF", cursor: "pointer" }}
        onClick={() => setOpen(!open)}
      >
        <TableCell
          align='left'
          className={classes.IdWidth}
          sx={{ borderTopLeftRadius: "15px", borderBottomLeftRadius: "15px" }}
        >
          {props?._id.substring(19).toUpperCase()}
        </TableCell>
        <TableCell align='left' className={classes.IdWidth}>
          {props?.buyerDetails?.shopName || props?.buyerDetails?.name}
        </TableCell>
        <TableCell align='left' className={classes.IdWidth}>
          {props?.paymentMode}
        </TableCell>

        {type === "Ready" ||
        type === "Shipping" ||
        type === "Delivered" ||
        type === "Packing" ||
        type === "Incoming" ? (
          <TableCell align='left' className={classes.IdWidth}>
            {props?.currentStatus && (
              <>
                {typeof props.currentStatus?.date === "string"
                  ? new Date(props.currentStatus?.date).toLocaleDateString()
                  : new Date(props.currentStatus?.date).toLocaleDateString()}
                <br />
                {typeof props.currentStatus?.date === "string"
                  ? new Date(props.currentStatus?.date).toLocaleTimeString(
                      "en-US"
                    )
                  : new Date(props.currentStatus?.date).toLocaleTimeString(
                      "en-US"
                    )}
              </>
            )}
          </TableCell>
        ) : (
          <TableCell align='left'>
            {/* "--:" */}
            12/3/2022 5:42:33 PM
            {/* {(timer.min < 10) && (timer.min !== 0)
              ? "0" + timer.min
              : timer.min < 10 && timer.min > 0
              ? timer.min
              : "--"} +
            ":" +
            {timer.sec < 10 && timer.sec !== 0
              ? "0" + timer.sec
              : timer.sec < 10 && timer.sec > 0
              ? timer.sec
              : "--"} */}
          </TableCell>
        )}

        <TableCell align='left' className={classes.IdWidth}>
          {props?.rider?.name}
        </TableCell>
        {/* <TableCell align='left' className={classes.IdWidth}>
          {props?.type}
        </TableCell> */}

        <TableCell
          style={{ textAlign: "center" }}
          align='left'
          className={classes.IdWidth}
        >
          {/* Display the effective total amount and, if applicable, the collected amount */}
          {props?.bill?.payableAmount}
          {totalCollected > 0 && ` (Paid: ${totalCollected})`}
        </TableCell>
        {/* <TableCell align="left" className={classes.IdWidth} >
          <p style={{backgroundColor:'#3366ff',alignItems:'center',borderRadius:'12px',paddingLeft:'35%'}}>4⛥</p>
          
        </TableCell> */}
        <TableCell
          align='left'
          className={classes.IdWidth}
          sx={{
            borderTopRightRadius: "15px",
            borderBottomRightRadius: "15px",
            textAlign: "center",
            width: "10px",
          }}
        >
          {props?.type === "Incoming" ? (
            props?.buyerDetails?.contact
          ) : props?.type === "Packing" ? (
            <>
              <Chip
                label={
                  props?.currentStatus?.status !== "rider_accepted"
                    ? "Assigning Rider"
                    : "Ready"
                }
                sx={
                  props?.currentStatus?.status !== "rider_accepted"
                    ? { backgroundColor: "#b1afaf", color: "white" }
                    : { backgroundColor: "#3FA436", color: "white" }
                }
                onClick={(e) => {
                  e.stopPropagation();
                  if (props?.currentStatus?.status !== "rider_accepted") {
                    printPicklistDocument(); // Function to print picklist

                    toast.error("Please wait till the Rider gets assigned");
                  } else {
                    printPicklistDocument();
                    readyResult([{ _id: props._id, amount: 0, period: 1 }]);
                  }
                }}
                color='success'
              />
            </>
          ) : props?.order_status === "Packed" ? (
            <>
              <Chip
                label='Print Invoice'
                sx={{ backgroundColor: "#3FA436", color: "white" }}
                onClick={(e) => {
                  e.stopPropagation();
                  generateAndOpenInvoice(); // Function to print picklist
                }}
                color='success'
              />
            </>
          ) : (
            props?.rider?.contact
          )}
          {ORDER_STATUSES.includes(props?.order_status) ? (
            <>
              {" "}
              <Chip
                label='Print Invoice'
                sx={{ backgroundColor: "#3FA436", color: "white" }}
                onClick={(e) => {
                  if (
                    !isInvoicePrinted &&
                    ORDER_STATUSES.includes(props?.order_status)
                  ) {
                    verifyInvoicePrint();
                    e.stopPropagation();
                    generateAndOpenInvoice(); // Function to print picklist
                  } else {
                    toast.error("Invoice already printed by user");
                    if (ORDER_STATUSES.includes(props?.order_status)) {
                      setPrintInvoiceModal(true);
                    }
                    e.stopPropagation();
                  }
                }}
                color='success'
              />
              <PrintInvoiceModal
                open={printInvoiceModal}
                onPrint={(e) => {
                  setPrintInvoiceModal(false);
                  generateAndOpenInvoice(); // Function to print picklist
                  e.stopPropagation();
                }}
                onClose={() => {
                  setPrintInvoiceModal(false);
                }}
                invoicePrintedDate={props.invoicePrintedDate}
              />
            </>
          ) : null}
        </TableCell>
        <TableCell>
          {props?.currentStatus &&
            props?.currentStatus?.status === "delivered" &&
            (totalCollected === 0 ||
              !totalCollected ||
              totalCollected < props?.order?.totalAmt) && (
              <Button
                variant='outlined'
                color='success'
                onClick={(e) => {
                  e.stopPropagation();
                  handleOpenModal(amountToCollect);
                }}
                // startIcon={<AttachMoneyIcon />}
              >
                Collect Cash
              </Button>
            )}
          <CashCollectionModal
            open={modalOpen}
            onClose={handleCloseModal}
            onReceive={() =>
              handleReceiveClick(
                finAmount,
                finPaymentMethod,
                finChequeNumber,
                finChequeDate,
                finPin
              )
            }
            initialAmount={totalAmount}
          />
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell colSpan={8}>
          <div style={{ borderRadius: "20px" }}>
            <Box
              sx={{
                boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
              }}
            >
              <Collapse in={open} timeout='auto' unmountOnExit>
                <div>
                  {type === "Incoming" ? (
                    <>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-evenly",
                          alignItems: "center",
                          flexDirection: "row",
                        }}
                      >
                        {/* <Box
                          sx={{
                            display: "flex",
                            padding: "10px",
                            justifyContent: "space-evenly",
                            alignItems: "center",
                            flexDirection: "row",
                          }}
                        > */}
                        <div>
                          <Typography
                            style={{ fontWeight: "600" }}
                            color='black'
                          >
                            Estimated Packing Time ( In Minutes )
                          </Typography>
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              padding: "1px 8px 1px 8px",
                              flexDirection: "row",
                              justifyContent: "space-around",
                            }}
                          >
                            <Button
                              sx={{
                                backgroundColor: "#fffff",
                                borderWidth: "0.5px",
                                height: "0.4rem",
                              }}
                              onClick={() => {
                                if (packingtime > 2) {
                                  setPackingtime(packingtime - 1);
                                }
                              }}
                            >
                              -
                            </Button>
                            <p style={{ marginLeft: "2%", width: "18px" }}>
                              {packingtime}
                            </p>
                            <Button
                              sx={{
                                backgroundColor: "#fffff",
                                borderWidth: "0.5px",
                                height: "0.4rem",
                              }}
                              onClick={() => setPackingtime(packingtime + 1)}
                            >
                              +
                            </Button>
                          </Box>
                        </div>
                        {props?.deliveryMode?.display ===
                          "Shop Delivery (Seller)" && (
                          <div>
                            <Typography style={{ fontWeight: "600" }}>
                              Delivery Boy Unavailable ? Switch to Platform
                            </Typography>
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                padding: "1px 8px 1px 8px",
                                flexDirection: "row",
                                // justifyContent: "space-around",
                              }}
                            >
                              <select
                                value={deliverMode}
                                onChange={(e: any) =>
                                  setDeliverMode(e.target.value)
                                }
                                name='delivery-option'
                              >
                                <option value={1}>Self Delivery</option>
                                <option value={0}>Platform</option>
                              </select>
                            </Box>
                          </div>
                        )}
                      </Box>
                    </>
                  ) : (
                    <>
                      <Box
                        sx={{
                          display: "flex",
                          // padding: "10px",
                          justifyContent: "space-evenly",
                          alignItems: "center",
                          flexDirection: "row",
                        }}
                      >
                        {/* <Tiny
                          style={{ fontSize: "20px", fontWeight: "600" }}
                          color='black'
                        >
                          Rider Details
                        </Tiny> */}
                        <img
                          src='https://i.pinimg.com/originals/a9/88/ef/a988ef63cfc68a7577da9e5cb469a768.jpg'
                          width='42px'
                          height='42px'
                          alt='Rider'
                        />
                        <Typography>{rider ? rider : "Unknown"}</Typography>
                        <FlexBox
                          style={{
                            color: "#3FA436",
                            // margin: "10px",
                            width: "30%",
                          }}
                          justifyContent='space-between'
                        >
                          <Button
                            variant='outlined'
                            color='success'
                            size='small'
                            onClick={() => openPhone(props?.rider?.contact)}
                            startIcon={<CallIcon />}
                          >
                            Call
                          </Button>
                          <Button
                            variant='outlined'
                            color='success'
                            onClick={() =>
                              openMap(props?.rider?.latestLocation?.coordinates)
                            }
                            startIcon={<LocationOnIcon />}
                          >
                            Track
                          </Button>
                          {/* {console.log(props.currentStatus.status, totalCollected, props.order.totalAmt)} */}
                        </FlexBox>
                      </Box>
                    </>
                  )}
                </div>
                <br />
                <FlexBox flexDirection='row'>
                  <Box className={classes.box}>
                    {acceptedItems.map((item: any, index: number) => {
                      return (
                        // <div>
                        <Card sx={{ padding: 1 }}>
                          <Stack
                            direction='row'
                            spacing={1}
                            justifyContent='space-between'
                            alignItems='center'
                            sx={{
                              // borderRadius: 4,
                              // boxShadow:
                              //   "rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgb(209, 213, 219) 0px 0px 0px 1px inset",
                              // padding: 1,
                              gap: "1.1rem",
                            }}
                          >
                            <FlexBox
                              flexDirection='row'
                              // justifyContent='space-between'
                              alignItems='center'
                              style={{
                                gap: "0.2rem",
                                flex: "2",
                              }}
                            >
                              <div>
                                <CardMedia
                                  component='img'
                                  image={
                                    item.product?.thumbImages?.[0]?.thumb ||
                                    "https://www.generationsforpeace.org/wp-content/uploads/2018/03/empty.jpg"
                                  }
                                  alt='item image'
                                  style={{
                                    width: 60,
                                    height: 60,
                                    borderRadius: 5,
                                  }}
                                />
                              </div>
                              <Box
                                sx={{
                                  marginLeft: 1,

                                  flex: 2,
                                }}
                              >
                                <H6
                                  style={{
                                    // width: "100%",
                                    wordWrap: "break-word",
                                  }}
                                  sx={{ fontSize: "0.8rem" }}
                                >
                                  {item.name}
                                </H6>
                                <H6 sx={{ fontSize: "0.7rem ", color: "grey" }}>
                                  {item?.product?.description || "-"}
                                </H6>

                                <FlexBox
                                  flexDirection='row'
                                  // justifyContent='space-between'

                                  style={{ gap: "1rem" }}
                                  // pr={4}
                                >
                                  {!item?.product?.minPrice ? (
                                    <Tiny>{formatRupee(item?.unitPrice)}</Tiny>
                                  ) : (
                                    <>
                                      <Tiny
                                        color='black'
                                        style={{ fontWeight: "bold" }}
                                      >
                                        MRP :{" "}
                                        {item?.product?.minPrice?.price &&
                                          formatRupee(
                                            item?.product?.minPrice?.price
                                          )}
                                      </Tiny>
                                      <Tiny
                                        color='black'
                                        style={{ fontWeight: "bold" }}
                                      >
                                        SP :{" "}
                                        {item?.product?.minPrice
                                          ?.sellingPrice &&
                                          formatRupee(
                                            item?.product?.minPrice
                                              ?.sellingPrice
                                          )}
                                      </Tiny>
                                      <Tiny
                                        color='black'
                                        style={{ fontWeight: "bold" }}
                                      >
                                        Qty {item.quantity}
                                      </Tiny>
                                    </>
                                  )}
                                </FlexBox>
                              </Box>
                            </FlexBox>

                            {props?.type === "Incoming" ? (
                              <FlexBox
                                style={{
                                  flex: "0.8",
                                  padding: "1rem 0rem",

                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                <Box>
                                  <H6 sx={{ fontSize: "12px" }}>
                                    Product Available
                                  </H6>
                                  <FlexBox
                                    flexDirection='row'
                                    style={{
                                      padding: "1rem 0rem",
                                    }}
                                    // p={2}
                                    justifyContent='space-between'
                                    alignItems='center'
                                  >
                                    <DoneTwoToneIcon
                                      style={{
                                        color: "green",
                                        fontSize: "16px",
                                        borderRadius: 4,
                                        padding: 1,
                                        border: "1px solid green",
                                        cursor: "pointer",
                                      }}
                                    />
                                    <CloseTwoToneIcon
                                      style={{
                                        color: "red",
                                        fontSize: "16px",
                                        borderRadius: 4,
                                        padding: 1,
                                        border: "1px solid red",
                                        cursor: "pointer",
                                      }}
                                      onClick={() => rejectItem(item.product)}
                                    />
                                  </FlexBox>
                                </Box>
                                <Box>
                                  <H6 sx={{ fontSize: "12px" }}>
                                    Available Qty
                                  </H6>

                                  <FlexBox
                                    key={index + 1}
                                    flexDirection='row'
                                    justifyContent='space-between'
                                    alignItems='center'
                                  >
                                    <RemoveOutlinedIcon
                                      style={{
                                        color: "black",
                                        fontSize: "16px",
                                        borderRadius: 4,
                                        padding: 1,
                                        border: "1px solid black",
                                        cursor: "pointer",
                                      }}
                                      onClick={() => {
                                        if (item.availableQty > 1) {
                                          setAvailableQty(
                                            index,
                                            acceptedItems[index].availableQty -
                                              1
                                          );
                                        } else if (item.availableQty === 1) {
                                          rejectItem(item.product);
                                        }
                                      }}
                                    />

                                    <div>
                                      <p>{item.availableQty}</p>
                                    </div>

                                    <AddOutlinedIcon
                                      style={{
                                        color: "black",
                                        fontSize: "16px",
                                        borderRadius: 4,
                                        padding: 1,
                                        border: "1px solid black",
                                        cursor: "pointer",
                                      }}
                                      onClick={() => {
                                        if (item.availableQty < item.quantity) {
                                          setAvailableQty(
                                            index,

                                            acceptedItems[index].availableQty +
                                              1
                                          );
                                        }
                                      }}
                                    />
                                  </FlexBox>
                                  {/* </div> */}
                                </Box>
                              </FlexBox>
                            ) : (
                              <Box
                                sx={{
                                  border: "1px solid green",
                                  borderRadius: "5px",
                                  padding: "1px 5px 1px 5px",
                                }}
                              >
                                <Tiny color='green'>Accepted</Tiny>
                              </Box>
                            )}
                            {/* </FlexBox> */}
                          </Stack>
                        </Card>
                        // </div>
                      );
                    })}
                    {/* <p>Rejected card</p> */}
                    {rejectedItems.map((item: any, index: any) => {
                      return (
                        <Card
                          sx={{
                            padding: 3,
                            borderRadius: 2,
                            background: "rgba(211,211,211,0.5)",
                          }}
                        >
                          <FlexBox
                            justifyContent='space-between'
                            alignItems='center'
                          >
                            <FlexBox>
                              <Box marginLeft={1}>
                                <H6>{item?.name}</H6>
                                <Tiny color='text.disabled' fontWeight={600}>
                                  <Tiny color='black'>{item?.price}</Tiny>
                                  <Divider color='black' />
                                  <Tiny color='black'>Qty</Tiny>
                                </Tiny>
                              </Box>
                            </FlexBox>

                            <div>
                              <H6>Product Available</H6>
                              <FlexBox
                                flexDirection='row'
                                justifyContent='space-evenly'
                              >
                                <DoneTwoToneIcon
                                  style={{
                                    color: "green",
                                    fontSize: "14px",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => acceptItem(item.product)}
                                />

                                <CloseTwoToneIcon
                                  style={{
                                    color: "red",
                                    fontSize: "14px",
                                    cursor: "pointer",
                                  }}
                                />
                              </FlexBox>
                            </div>
                          </FlexBox>
                        </Card>
                      );
                    })}
                  </Box>
                  {/* <Box className={classes.box}>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-evenly",
                      }}
                    >
                      <Box>
                        <Box
                          sx={{
                            height: "15rem",
                            display: "flex",
                            justifyContent: "space-evenly",
                            flexDirection: "column",
                            flexWrap: "nowrap",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                              flexDirection: "row",
                            }}
                          >
                            <Tiny style={{ fontWeight: "600" }}>
                              Order Total
                            </Tiny>
                            <Tiny style={{ fontWeight: "600" }}>
                              {total} ₹{props.order.mainAmt}
                            </Tiny>
                          </Box>
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                              flexDirection: "row",
                            }}
                          >
                            <Tiny>Delivery Charges</Tiny>
                            <Tiny>0</Tiny>
                          </Box>
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                              flexDirection: "row",
                            }}
                          >
                            <Tiny>Gateway Charges</Tiny>
                            <Tiny>0</Tiny>
                          </Box>
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                              flexDirection: "row",
                            }}
                          >
                            <Tiny>Insurance</Tiny>
                            <Tiny>0</Tiny>
                          </Box>
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                              flexDirection: "row",
                            }}
                          >
                          <Tiny>Total Commission</Tiny>
                            <Tiny>
                              {props.items[0].commission.amounts.total}
                            </Tiny>
                          </Box>
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                              flexDirection: "row",
                            }}
                          >
                            <Tiny>Coupon Applied</Tiny>
                            <Tiny>0</Tiny>
                          </Box>
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                              flexDirection: "row",
                              width: "10vw",
                            }}
                          >
                            <Tiny style={{ fontWeight: "600" }}>
                              Total Amount{"   "}
                            </Tiny>
                            <Tiny style={{ fontWeight: "600" }}>
                              {"  "}₹
                              {(props?.order?.totalAmt ?? 0) -
                                (props?.commission?.restaurantGst ?? 0) +
                                (props?.couponProvidedBy === "platform" &&
                                props?.couponDeduction
                                  ? props.couponDeduction
                                  : 0)}
                            </Tiny>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                    <Box></Box>
                  </Box> */}
                </FlexBox>
                <Divider sx={{ my: 2 }} />
                <FlexBox
                  className={classes.tyrow}
                  justifyContent='space-around'
                  alignItems='center'
                >
                  {type === "Packing" ? (
                    <Chip
                      label={
                        props?.currentStatus?.status !== "rider_accepted"
                          ? "Assigning Rider"
                          : "Ready"
                      }
                      sx={{ backgroundColor: "#3FA436", color: "white" }}
                      onClick={handleReadyClick}
                      color='success'
                    />
                  ) : type === "Incoming" ? (
                    <>
                      <Chip
                        onClick={() => approvalAlert("reject")}
                        label='Reject Order'
                        sx={{ backgroundColor: "#E64242", color: "white" }}
                        color='success'
                        // props={props}
                      />

                      <Chip
                        onClick={() => approvalAlert("accept")}
                        label='Accept Order'
                        color='success'
                        sx={{ backgroundColor: "#3FA436", color: "white" }}
                      />
                    </>
                  ) : (
                    <></>
                  )}
                </FlexBox>
              </Collapse>
            </Box>
          </div>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}
interface MoneyCollected {
  acceptorId: string;
  receivedAmount: number;
  riderId: string;
}
interface amounts {
  total: number;
}

interface Order {
  moneyCollected: MoneyCollected[];
  order: {
    main: number;
    net: number;
    gst: number;
    totalAmt: number;
    discount: number;
  };
}

function calculateTotalCollectedAmount(orders: Order[]): number {
  let totalCollected = 0;
  orders.forEach((order) => {
    if (Array.isArray(order.moneyCollected)) {
      order.moneyCollected.forEach((mc: MoneyCollected) => {
        if (mc && typeof mc.receivedAmount === "number") {
          totalCollected += mc.receivedAmount;
        }
      });
    }
  });
  return totalCollected;
}
function calculateTotalAmount(orders: Order[]): number {
  // console.log(orders);
  let totalDeliveredAmount = 0;
  orders.forEach((order) => {
    // Since amounts is an object, directly access the 'total' property
    if (order.order && typeof order.order.totalAmt === "number") {
      totalDeliveredAmount += order.order.totalAmt;
    }
  });
  return totalDeliveredAmount;
}

export default function CollapsibleTable(props: {
  type: any;
  deliveryType: string;
  setIncomingorderCount: any;
  setPackingOrderCount: any;
  setReadyOrderCount: any;
  setShippingOrderCount: any;
  setDeliveredOrderCount: any;
  setTotalCollected: any;
  setTotalDeliveredAmount: any;
  setDeliveredData: any;
}) {
  const classes = useStyles();
  const [value, setValue] = useState("");
  const [item, setItem] = useState([]);

  const [ratingBox, setRatingBox] = useState(false);
  const [startDate, setStartDate] = useState(
    new Date().toISOString().split("T")[0]
  );
  const [endDate, setEndDate] = useState(
    new Date().toISOString().split("T")[0]
  );
  type OrderType = "Incoming" | "Packing" | "Ready" | "Shipping" | "Delivered";
  const [riderFilter, setRiderFilter] = useState("");
  const [paymentFilter, setPaymentFilter] = useState(""); // 'All', 'Paid', 'Unpaid'

  const handleRiderChange = (event: any) => {
    setRiderFilter(event.target.value);
  };

  const handlePaymentChange = (event: any) => {
    setPaymentFilter(event.target.value);
  };

  interface GetDataProps {
    type: OrderType;
  }
  interface GetDataProps {
    type: OrderType;
    setIncomingorderCount: (count: number) => void;
    setPackingOrderCount: (count: number) => void;
    setReadyOrderCount: (count: number) => void;
    setShippingOrderCount: (count: number) => void;
    setDeliveredOrderCount: (count: number) => void;
    setTotalCollected: (total: number) => void;
    setTotalDeliveredAmount: (total: number) => void;
    setDeliveredData: (data: []) => void;
  }
  const getData = async (
    startdate: any,
    enddate: any,
    props2: GetDataProps
  ) => {
    try {
      const statusMap: Record<OrderType, string> = {
        Incoming: "placed",
        Packing: "processing",
        Ready: "ready",
        Shipping: "shipping",
        Delivered: "delivered",
      };

      // Default status handling to address TypeScript concerns about undefined statuses
      const status = statusMap[props2.type] || "shipping";

      const url = (type: string) =>
        `${API.sellerUrl}orders/type/${type}?startDate=${
          startdate || startDate
        }&endDate=${enddate || endDate}`;

      const [
        res,
        placedRes,
        processingRes,
        readyRes,
        shippingRes,
        deliveredRes,
      ] = await Promise.all([
        API.getData(url(status)),
        API.getData(url("placed")),
        API.getData(url("processing")),
        API.getData(url("ready")),
        API.getData(url("shipping")),
        API.getData(url("delivered")),
      ]);

      const qty = res.data;
      const incomingOrder = placedRes.data;
      const packingOrder = processingRes.data;
      const readyOrder = readyRes.data;
      const shippingOrder = shippingRes.data;
      const deliveredOrder = deliveredRes.data;
      const totalCash = calculateTotalCollectedAmount(deliveredRes.data);
      const totalAmt = calculateTotalAmount(deliveredRes.data);
      // console.log(totalAmt);
      props.setIncomingorderCount(incomingOrder.length);
      props.setPackingOrderCount(packingOrder.length);
      props.setReadyOrderCount(readyOrder.length);
      props.setShippingOrderCount(shippingOrder.length);
      props.setDeliveredOrderCount(deliveredOrder.length);
      props.setTotalCollected(totalCash);
      props.setTotalDeliveredAmount(totalAmt);
      props.setDeliveredData(deliveredRes.data);

      setItem(qty);
    } catch (error) {
      console.error(error);
    }
  };

  const handleClick = (val: string) => {
    value === val ? setValue("") : setValue(val);
  };
  const [rating, setRating] = useState<number | null>(2);
  useEffect(() => {
    if (startDate && endDate) {
      getData(startDate, endDate, props); // Ensure props is structured correctly as expected by getData
    }
  }, [startDate, endDate, props.type, value]);
  const token = localStorage.getItem("accessToken");
  const { user }: AuthState = useContext(AuthContext);

  const [orderStatus, setOrderStatus] = useState(null);

  useEffect(() => {
    // Create the socket connection
    const socket = io(config.socketUrl, {
      reconnection: true,
      reconnectionDelay: 100,
      auth: {
        type: "seller",
        userId: user?._id,
        session: token,
      },
    });

    socket.on("connect", () => {});
    socket.on("orderStatusUpdated", (data) => {
      if (startDate && endDate) {
        getData(startDate, endDate, props); // Pass the required arguments
      }
      setOrderStatus(data);
    });

    // Clean up the socket connection on component unmount
    return () => {
      socket.disconnect();
    };
  }, [item]);
  interface DateRangePickerProps {
    onDateChange: (date: string, which: "start" | "end") => void;
  }

  const handleDateChange = (date: string, which: "start" | "end"): void => {
    if (which === "start") {
      setStartDate(date);
    } else {
      setEndDate(date);
    }
  };
  const handleDataRefresh = () => {
    if (startDate && endDate) {
      getData(startDate, endDate, props);
    }
  };
  interface Rider {
    name: string;
  }
  interface item {
    rider?: Rider;
  }
  const uniqueRiders = useMemo(() => {
    const riderNames = new Set<string>();
    item.forEach((order: any) => {
      if (order.rider?.name) {
        riderNames.add(order.rider.name);
      }
    });
    return Array.from(riderNames);
  }, [item]);

  interface PinData {
    name: string;
    pin: string;
  }
  const [pins, setPins] = useState<PinData[]>([]);
  useEffect(() => {
    const fetchPins = async () => {
      try {
        const response = await axios.get<PinData[]>(
          "https://reunite.fairdeal.market/acceptor_pin_manage.php?api_key=Shubjhsdgwegdhjwgnbjshgdhjan"
        );
        setPins(response.data);
      } catch (error) {
        console.error("Failed to fetch pins:", error);
      }
    };
    fetchPins();
  }, []);

  // console.log(item, "item");

  return (
    <div style={{ width: "100%" }}>
      <DateRangePicker onDateChange={handleDateChange} />
      <TableContainer sx={{ backgroundColor: " #f3f4f9" }}>
        <Table>
          {/* <TableHead className={classes.boxColor}> */}
          <tbody>
            <tr
              className={classes.row}
              style={{
                backgroundColor: "#fcf6d7",
                boxShadow: " 0px 4px 4px rgba(0, 0, 0, 0.25)",
                width: "80%",
              }}
            >
              <TableCell
                align='left'
                className={classes.IdWidth}
                sx={{
                  borderTopLeftRadius: "15px",
                  borderBottomLeftRadius: "15px",
                }}
              >
                Code
              </TableCell>
              <TableCell align='left' className={classes.IdWidth}>
                Name
              </TableCell>
              <TableCell align='left' className={classes.IdWidth}>
                Payment
              </TableCell>
              {props.type === "Incoming" ? (
                <TableCell
                  align='left'
                  className={classes.IdWidthy}
                  onClick={() => handleClick("createdAt")}
                  sx={{ fontWeight: 600 }}
                >
                  Order Date{" "}
                  {value === "createdAt" ? (
                    <ArrowDropUpTwoToneIcon />
                  ) : (
                    <ArrowDropDownTwoToneIcon />
                  )}
                </TableCell>
              ) : (
                <TableCell align='left' className={classes.IdWidth}>
                  Packing Time
                </TableCell>
              )}
              <TableCell align='left' className={classes.IdWidth}>
                Rider Name
              </TableCell>
              {/* <TableCell align='left' className={classes.IdWidth}>
              Status
            </TableCell> */}
              <TableCell
                align='left'
                className={
                  props.type === "Incoming"
                    ? classes.IdWidthy
                    : classes.IdWidthx
                }
                // style={{ backgroundColor: "red" }}
                onClick={() => handleClick("mainAmt")}
                sx={{
                  fontWeight: 600,
                  textAlign: "center",
                }}
              >
                Total
                {value === "mainAmt" ? (
                  <ArrowDropUpTwoToneIcon />
                ) : (
                  <ArrowDropDownTwoToneIcon />
                )}
              </TableCell>
              <TableCell
                align='left'
                className={classes.IdWidth}
                sx={{
                  borderTopRightRadius: "15px",
                  borderBottomRightRadius: "15px",
                  textAlign: "center",
                }}
              >
                {props.type === "Incoming" ? (
                  "Buyer Contact" // Assuming you display buyer contact information or a link/button to contact the buyer
                ) : props.type === "Ready" || props.type === "Shipping" ? (
                  <>Rider</>
                ) : (
                  "Action" // Actions applicable to other statuses
                )}
              </TableCell>
              <TableCell
                align='left'
                className={classes.IdWidth}
                sx={{
                  borderTopRightRadius: "15px",
                  borderBottomRightRadius: "15px",
                  textAlign: "center",
                }}
              >
                Collection
              </TableCell>
            </tr>
          </tbody>
          {/* </TableHead> */}
          <Stack direction='row' spacing={2}>
            <Select
              value={riderFilter}
              onChange={handleRiderChange}
              displayEmpty
            >
              <MenuItem value=''>All Riders</MenuItem>
              {/* Dynamically list all riders */}
              {uniqueRiders.map((riderName) => (
                <MenuItem key={riderName} value={riderName}>
                  {riderName}
                </MenuItem>
              ))}
            </Select>
            <Select
              value={paymentFilter}
              onChange={handlePaymentChange}
              displayEmpty
            >
              <MenuItem value=''>All</MenuItem>
              <MenuItem value='Paid'>Paid</MenuItem>
              <MenuItem value='Unpaid'>Unpaid</MenuItem>
            </Select>
          </Stack>
          <Box className={classes.trow}></Box>
          <TableBody>
            {item.length ? (
              item
                .filter((item: any) => {
                  return (
                    (!riderFilter || item.rider.name === riderFilter) &&
                    (!paymentFilter ||
                      (paymentFilter === "Paid" && item.moneyCollected) ||
                      (paymentFilter === "Unpaid" && !item.moneyCollected))
                  );
                })
                .sort((objA: any, objB: any) => {
                  return value === "mainAmt"
                    ? objA.order.mainAmt < objB.order.mainAmt
                      ? -1
                      : 1
                    : value === "createdAt"
                    ? new Date(objA.createdAt) < new Date(objB.createdAt)
                      ? -1
                      : 1
                    : 0;
                })
                .map((trow: any, index) => (
                  <>
                    {/* This is the Order List which show on screen */}
                    <OrderList
                      {...trow}
                      pins={pins}
                      key={trow._id}
                      type={props.type}
                      getData={getData}
                    />
                  </>
                ))
            ) : (
              <h4 style={{ width: "20vw" }}>
                Currently there are no available orders...
              </h4>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <div style={{ display: "none" }}>
        <TableContainer sx={{ backgroundColor: " #f3f4f9" }}>
          <Table>
            {/* <TableHead className={classes.boxColor}> */}
            <tr
              className={classes.row}
              style={{
                backgroundColor: "#fcf6d7",
                boxShadow: " 0px 4px 4px rgba(0, 0, 0, 0.25)",
                // borderRadius: "200px",
                width: "100%",
                // borderTopLeftRadius: "10px",
              }}
            >
              <TableCell
                align='left'
                className={classes.IdWidth}
                sx={{
                  borderTopLeftRadius: "15px",
                  borderBottomLeftRadius: "15px",
                }}
              >
                Code
              </TableCell>
              <TableCell align='left' className={classes.IdWidth}>
                Name
              </TableCell>
              <TableCell align='left' className={classes.IdWidth}>
                Payment
              </TableCell>
              {props.type === "Incoming" ? (
                <TableCell
                  align='left'
                  className={classes.IdWidthy}
                  onClick={() => handleClick("createdAt")}
                >
                  Order Date{" "}
                  {value === "createdAt" ? (
                    <ArrowDropUpTwoToneIcon />
                  ) : (
                    <ArrowDropDownTwoToneIcon />
                  )}
                </TableCell>
              ) : (
                <TableCell align='left' className={classes.IdWidth}>
                  Packing Time
                </TableCell>
              )}
              <TableCell align='left' className={classes.IdWidth}>
                Rider Name
              </TableCell>
              <TableCell align='left' className={classes.IdWidth}>
                Status
              </TableCell>
              <TableCell
                align='left'
                className={
                  props.type === "Incoming"
                    ? classes.IdWidthy
                    : classes.IdWidthx
                }
                // style={{marginTop: "10px"}}
                onClick={() => handleClick("mainAmt")}
              >
                <FlexBox>
                  Total
                  {value === "mainAmt" ? (
                    <ArrowDropUpTwoToneIcon />
                  ) : (
                    <ArrowDropDownTwoToneIcon />
                  )}
                </FlexBox>
              </TableCell>
              <TableCell align='left' className={classes.IdWidth}>
                Rating
              </TableCell>
              <TableCell
                align='left'
                className={classes.IdWidth}
                sx={{
                  borderTopRightRadius: "15px",
                  borderBottomRightRadius: "15px",
                  textAlign: "center",
                }}
              >
                Action
              </TableCell>
            </tr>
            {/* </TableHead> */}
            <Box className={classes.trow}></Box>
            <TableBody>
              {item.length &&
                item
                  // .filter((item:any) => item.deliverMode === props.deliveryType)
                  // eslint-disable-next-line array-callback-return
                  // @ts-ignore
                  .sort((objA: any, objB: any) => {
                    return value === "mainAmt"
                      ? +objA.order.mainAmt < +objB.order.mainAmt
                        ? -1
                        : 1
                      : value === "createdAt"
                      ? +objA.createdAt < +objB.createdAt
                        ? -1
                        : 1
                      : null;
                  })
                  .map((trow: any, index) => {
                    return (
                      <OrderList
                        {...trow}
                        key={index}
                        type={props.type}
                        getData={getData}
                      />
                    );
                  })}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <Box
        sx={{
          width: "22rem",

          backgroundColor: "#ffffff",
          borderRadius: "20px",
          padding: "2%",
          boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
          display: ratingBox ? "block" : "none",
        }}
      >
        <h2>Order Rating</h2>
        <hr />
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignContent: "center",
          }}
        >
          <p>Customer</p>

          <Rating
            name='simple-controlled'
            value={rating}
            onChange={(event, newValue) => {
              setRating(newValue);
            }}
          />
        </div>
        <p>Reason behind this rating</p>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            color: "black",
          }}
        >
          <Button>Behaviour</Button>
          <Button>Hygiene</Button>
          <Button>Cancellation</Button>
        </div>

        <hr />
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignContent: "center",
          }}
        >
          <p>Rider</p>

          <Rating
            name='simple-controlled'
            value={rating}
            onChange={(event, newValue) => {
              setRating(newValue);
            }}
          />
        </div>
        <p>Reason behind this rating</p>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignContent: "center",
          }}
        >
          <Button>Behaviour</Button>
          <Button>Hygiene</Button>
          <Button>Cancellation</Button>
        </div>

        <Button
          sx={{
            backgroundColor: "yellow",
            fontWeight: "500",
            padding: "2% 44%",
            marginTop: "2%",
          }}
        >
          Done
        </Button>
      </Box>
    </div>
  );
}
