import CancelIcon from "@mui/icons-material/Cancel";
import NotificationAddIcon from "@mui/icons-material/NotificationAdd";
import NotificationsIcon from "@mui/icons-material/Notifications";
import Avatar from "@mui/material/Avatar";
import Popover from "@mui/material/Popover";
import TableCell from "@mui/material/TableCell";
import { deepOrange } from "@mui/material/colors";
import AuthContext, { AuthState } from "contexts/JWTAuthContext";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { API } from "service/api";

const NotificationSearch = () => {
  const [notify, setNotify] = useState(false); ///Notification CancelIcon
  const [notificationData, setNotificationData] = useState([]);
  const [newNotification, setNewNotification] = useState(false);
  const token = localStorage.getItem("accessToken");
  const { user }: AuthState = useContext(AuthContext);

  const [alert, setAlert] = useState(false); ///CancelIcon
  const [alertData, setAlertData] = useState([]);

  const [popup, setPopup] = useState(false);
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClickz = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const myRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    API.getData(API.sellerUrl + "notifications/type/:turn-in/").then(
      (result) => {
        setNotificationData(result.data);
      }
    );
  }, []);

  // useEffect(() => {
  //   async function fetchData() {
  //     try {
  //       const response = await fetch(
  //         "${API.sellerUrl}notifications/type/?alert:true"
  //       );
  //       // pass url
  //       console.log(response);

  //       const data = await response.json();

  //       setAlertData(data.notificationData);
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   }
  //   fetchData();
  // }, []);

  //ALert
  useEffect(() => {
    API.getData(API.sellerUrl + "notifications/type/:type/").then((result) => {
      setAlertData(result.data);
    });
  }, []);

  useEffect(() => {
    function handleClickOutside(event: any) {
      if (myRef.current && !myRef.current.contains(event.target)) {
        setNotify(false);
        setAlert(false);
      }
    }
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [myRef]);

  ///Notification
  const handleClick = () => {
    if (notify) {
      setNotify(false);
    } else {
      setNotify(true);
    }
  };

  const notifier = {
    display: notify ? "block" : "none",
    position: "absolute",
    top: "6rem",
    left: "55rem",
    height: "392px",
    overflow: "auto",
    zIndex: "3",
    backgroundColor: "white",
    borderRadius: "20px",
    boxShadow: "2px 2px 4px rgba(0, 0, 0, 0.25)",
  } as React.CSSProperties;
  const alerts = {
    display: alert ? "block" : "none",
    position: "absolute",
    top: "6rem",
    left: "52rem",
    zIndex: 1000,
    backgroundColor: "white",
    width: "411px",
    borderRadius: "20px",
    marginRight: "10px",
    boxShadow: "2px 2px 4px rgba(0, 0, 0, 0.25)",
    overflow: "scroll",
    overflowX: "hidden",
    height: "50vh",
  } as React.CSSProperties;
  const notification = {
    margin: "0rem",
    width: "411px",
    alignText: "center",
    borderCollapse: "collapse",
    borderSpacing: "4rem",
    // borderTopRadius: "20%",
  } as React.CSSProperties;

  return (
    <>
      <div ref={myRef}>
        <div style={{ display: "flex", alignItems: "center" }}>
          <div style={{ marginRight: "3rem", cursor: "pointer" }}>
            {/* <SearchInput placeholder='Search anything' /> */}
          </div>
          {/* <div
          style={{
            marginRight: "10px",
            backgroundColor: "white",
            borderRadius: "5px",
            padding: "1.5%",
            cursor: "pointer",
          }}
        >
          <WarningAmberIcon onClick={handleAlert} />
        </div> */}

          <div
            style={{
              marginRight: "10px",
              backgroundColor: "white",
              borderRadius: "20px",
              border: "1px solid #f3f4f9",
              padding: "1.5%",
              cursor: "pointer",
            }}
          >
            {newNotification ? (
              <NotificationAddIcon
                style={{ width: "2vw", color: "red" }}
                onClick={handleClick}
              />
            ) : (
              <NotificationsIcon
                style={{ width: "2vw" }}
                onClick={handleClick}
              />
            )}
          </div>
          <div
            onClick={(e: any) => handleClickz(e)}
            style={{
              display: "flex",
              alignItems: "center",
              cursor: "pointer",
              justifyContent: "space-between",
              gap: "0.5rem",
              fontWeight: "bold",
            }}
            aria-describedby={id}
          >
            <Avatar
              sx={{ bgcolor: deepOrange[500] }}
              alt={user?.name}
              src='/broken-image.jpg'
            />
            <p>{user?.name}</p>
          </div>
        </div>

        <div style={notifier} className='notificationBox'>
          <CancelIcon
            sx={{
              marginLeft: "90%",
              marginTop: "1%",
              // height: "50px",
              cursor: "pointer",
              fontSize: "1.5rem",
            }}
            onClick={() => setNotify(false)}
          />

          <table style={notification}>
            <thead
              style={{
                height: "2rem",
              }}
            >
              {}
              <tr>
                <th>Notifications</th>
                <th>Date</th>
              </tr>
            </thead>
            {/* {notificationData.map((notification, index) => (
            <li key={index}>{notification.message}</li>
          ))} */}
            {notificationData?.map((e: any, index) => {
              return (
                <tr key={index} style={{ alignItems: "center" }}>
                  <TableCell sx={{ fontSize: "12px", width: "60%" }}>
                    {e.message}
                  </TableCell>
                  <TableCell sx={{ fontSize: "12px", width: "40%" }}>
                    {new Date(e?.createdAt)?.toLocaleString()}
                  </TableCell>
                </tr>
              );
            })}
          </table>
        </div>

        {/* ///Alerts */}

        <div style={alerts} className='alertBox'>
          <CancelIcon
            sx={{
              marginLeft: "90%",
              marginTop: "1%",
              height: "20px",
              fontSize: "1.5rem",
            }}
            onClick={() => setAlert(false)}
          />
          <table style={notification}>
            <thead
              style={{
                // justifyContent: "space-around",
                height: "2rem",
              }}
            >
              <tr>
                <th>Alerts</th>
                <th>Date</th>
              </tr>
              {/* <th>Want to change</th> */}
            </thead>

            {/* ///Alert */}

            {alertData?.map((e: any, index) => {
              return (
                <tr
                  key={index}
                  style={{ alignItems: "center", height: "1rem" }}
                >
                  <TableCell sx={{ fontSize: "12px", width: "60%" }}>
                    {e.message}
                  </TableCell>
                  <TableCell sx={{ fontSize: "12px", width: "20%" }}>
                    {e.createdAt}
                  </TableCell>

                  {/* <TableCell sx={{ display: "flex" }}>
                  <Button sx={{ backgroundColor: "yellow", padding: "none" }}>
                    no
                  </Button>
                  <Button sx={{ backgroundColor: "#B1F5D1", fontSize: 10 }}>
                    yes
                  </Button>
                </TableCell> */}
                </tr>
              );
            })}
          </table>
        </div>

        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
        >
          <TableCell
            style={{ cursor: "pointer" }}
            onClick={() => navigate("/dashboard/user-profile")}
          >
            Profile & Account
          </TableCell>
          <br />
          <TableCell
            style={{ cursor: "pointer" }}
            onClick={() => {
              localStorage.removeItem("accessToken");
              window.location.href = "/";
            }}
          >
            Signout
          </TableCell>
        </Popover>
      </div>
    </>
  );
};

export default NotificationSearch;
