import { Store } from 'pullstate';

interface IHeaderStore {
	maintenance: boolean;
	maintenanceCompleted: boolean;
	versionExpired: boolean;
	updateAvailble: boolean;
	doUpdateLater: boolean;
	sessionExpired: boolean;
	forbidden: boolean;
	newVersion: {
		version: string;
		description: string;
	};
}

export const HeaderStore = new Store<IHeaderStore>({
	maintenance: false,
	maintenanceCompleted: false,
	versionExpired: false,
	updateAvailble: false,
	doUpdateLater: false,
	sessionExpired: false,
	forbidden: false,
	newVersion: {
		version: "",
		description: ""
	}
});
