import {
  Box,
  ClickAwayListener,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  Switch,
} from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import { styled } from "@mui/material/styles";
import * as React from "react";
import { useState } from "react";
import { toast } from "react-hot-toast";
import { API } from "service/api";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export default function ProductDisable({ product }: any) {
  const [open, setOpen] = React.useState(false);
  const [showTimePicker, setShowTimePicker] = useState(false);
  const [date, setDate] = useState("");

  let [check, setCheck] = React.useState(
    product.status === "active" ? true : false
  );

  const handleChange = () => {
    if (check) {
      setCheck(false);
      setOpen(true);
    } else {
      setCheck(true);
      handleEnableProduct();
    }
  };
  //  the dialog if the click was outside of it
  // will still be open
  const handleClickAway = () => {
    setOpen(true);
  };

  const [optionDisable, setOptionDisable] = useState("");
  const handleRadio = (e: any) => {
    if (e.target.value === "other") {
      setShowTimePicker(true);
      setOptionDisable(e.target.value);
    } else {
      setOptionDisable(e.target.value);
      setShowTimePicker(false);
    }
  };

  const handleEnableProduct = () => {
    const data: any = {
      product: {
        id: product._id,
      },
    };
    API.postData(API.sellerUrl + `timings/removemanualschedule`, data)
      .then((result) => {
        toast("Successfully changed the status ");
      })
      .catch((err) => {
        toast.error("Some error occurred while submitting request.");
      });
  };
  const handleDisableProduct = () => {
    const data = {
      endDateTime: new Date().getTime() + 2 * 60 * 60 * 1000,
      product: {
        id: product._id,
      },
    };

    if (optionDisable === "2 Hours") {
      data.endDateTime = data.endDateTime + 2 * 60 * 60 * 1000;
    } else if (optionDisable === "4 Hours") {
      data.endDateTime = data.endDateTime + 4 * 60 * 60 * 1000;
    } else if (optionDisable === "tomorrow") {
      data.endDateTime = data.endDateTime + 24 * 60 * 60 * 1000;
    } else if (optionDisable === "later") {
      data.endDateTime = new Date().getTime() - 60 * 1000;
    } else if (optionDisable === "other") {
      data.endDateTime = new Date(date).getTime();
    }

    API.postData(API.sellerUrl + `timings/manualschedule`, data)
      .then((result) => {
        toast("Successfully changed the status ");
        setShowTimePicker(false);
        setDate("");
      })
      .catch((err) => {
        toast.error("Some error occurred while submitting request.");
      });
    setOpen(false);
  };

  const handleClose = () => {
    setOpen(false);
    check = product.status;
    setCheck(check);
  };
  return (
    <div>
      <Stack sx={{ alignItems: "center" }}>
        <Switch
          inputProps={{ "aria-label": "controlled" }}
          checked={check}
          onChange={handleChange}
        ></Switch>
      </Stack>

      <ClickAwayListener onClickAway={handleClickAway}>
        <BootstrapDialog
          // onClose={handleClose}
          aria-labelledby='customized-dialog-title'
          open={open}
        >
          <DialogContent dividers>
            <Box>
              <h2>{product.name}</h2>
              <hr />
              <h3> Auto turn on after</h3>
              <RadioGroup
                aria-labelledby='demo-radio-buttons-group-label'
                defaultValue='2 Hours'
                name='radio-buttons-group'
                onChange={handleRadio}
              >
                <FormControlLabel
                  value='2 Hours'
                  control={<Radio />}
                  label='2 Hours'
                />
                <FormControlLabel
                  value='4 Hours'
                  control={<Radio />}
                  label='4 Hours'
                />
                <FormControlLabel
                  value='tomorrow'
                  control={<Radio />}
                  label='Tomorrrow before first slot'
                />
                <div style={{ display: "flex", alignItems: "center" }}>
                  <FormControlLabel
                    value='other'
                    control={<Radio />}
                    label='Specific date or time'
                  />
                  {showTimePicker ? (
                    <label htmlFor='datePicker'>
                      <input
                        id='datePicker'
                        style={{
                          height: "4vh",
                          borderRadius: "0.5rem",
                          outlineColor: "#3b97c6",
                        }}
                        type='datetime-local'
                        onChange={(e) => setDate(e.target.value)}
                      />
                    </label>
                  ) : null}
                </div>
                <hr />
                <h3>Manually turn on</h3>
                <FormControlLabel
                  value='later'
                  control={<Radio />}
                  label='I will turn it on myself'
                />
                <p>
                  {product.name} will be disable for customers on app untill you
                  turn it back on
                </p>
              </RadioGroup>
            </Box>
          </DialogContent>
          <DialogActions
            sx={{ display: "flex", justifyContent: "space-evenly" }}
          >
            <Button
              sx={{ backgroundColor: "yellow", color: "black" }}
              variant='contained'
              autoFocus
              onClick={handleDisableProduct}
            >
              Disable
            </Button>
            <Button
              sx={{ backgroundColor: "red" }}
              variant='contained'
              autoFocus
              onClick={handleClose}
            >
              Cancel
            </Button>
          </DialogActions>
        </BootstrapDialog>
      </ClickAwayListener>
    </div>
  );
}
