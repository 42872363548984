import {
  Box,
  Button,
  Drawer,
  List,
  ListItemButton,
  styled,
  Theme,
  useMediaQuery,
} from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import PinForProduct from "components/PinForProduct";
import { Dayjs } from "dayjs";
import React, { FC, Fragment, useState } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import ScrollBar from "simplebar-react";
import { API } from "../../service/api";
import ShopDisableSwitch from "./ShopDisable";
import "./Sidebar.css";
import topMenuList from "./topMenuList";
interface SideNavBarProps {
  showMobileSideBar: boolean;
  closeMobileSideBar: () => void;
}

// custom styled components
const MainMenu = styled(Box)(({ theme }) => ({
  left: 0,
  width: "12rem",
  height: "100%",
  position: "fixed",
  paddingTop: "2rem",
  boxShadow: theme.shadows[2],
  transition: "left 0.3s ease",
  zIndex: theme.zIndex.drawer + 11,
  backgroundColor: "#000000",
  [theme.breakpoints.down("md")]: { left: -80 },
  "& .simplebar-track.simplebar-vertical": { width: 7 },
  "& .simplebar-scrollbar:before": {
    background: theme.palette.text.primary,
  },
}));

const StyledLogo = styled(ListItemButton)(() => ({
  marginTop: "1rem",
  marginBottom: "1rem",

  marginLeft: "1.2rem",
}));

const calendar = {
  backgroundColor: "white",
  width: "11rem",
  // color: 'red',
  alignSelf: "center",
};
const offNotify = () =>
  toast("You will not be visible to our customers😫", {
    duration: 1000,
  });
const onNotify = () =>
  toast("Welcome back, May you have a good day🤩", {
    duration: 1000,
  });

const StyledListItemButton = styled(ListItemButton)(() => ({
  marginBottom: "1rem",

  "&:hover": {
    backgroundColor: "#3366FF",
    color: "white",
    borderRadius: "10%",
  },
}));
const AutoTurn = styled(Button)(() => ({
  backgroundColor: "theme.palette.text.primary",
  color: "white",
  "&:hover": {
    backgroundColor: "#3366FF",
    color: "yellow",
    borderRadius: "10%",
  },
}));

// root component
const DashboardSideBar: FC<SideNavBarProps> = ({
  showMobileSideBar,
  closeMobileSideBar,
}) => {
  const navigate = useNavigate();
  const [value, setValue] = React.useState<Dayjs | null>(null);
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  const [sf, setSf] = useState(true);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (checked) {
      setAnchorEl(event.currentTarget);
      setSf(true);
      offNotify();
    } else {
      onNotify();
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const [timing, setTiming] = useState(false);

  const toggle = {
    // height: "32rem",
    width: "18rem",
    alignContent: "center",
    margin: 0,
    fontSize: ".65rem",
    backgroundColor: "#000000",
    color: "white",
    padding: "15px",
    borderRadius: "2%",
    position: "fixed",
    top: "108px",
    left: "85px",
    zIndex: "1",
  };

  // defining the offline message to seller

  // const [close, setClose] = React.useState(false);

  // const handleClickOpen = () => {
  //   setClose(true);
  // };

  // const handleClose2 = () => {
  //   setClose(false);
  // };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const [active, setActive] = useState("Dashboard");
  const downMd = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));
  const [checked, setChecked] = useState(true);
  const [date, setDate] = useState("");
  const [isPinModal, setIsPinModal] = useState<any>(false)
  const [productItem, setProductItem] = useState<any>();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
  };

  function getWithExpiry(key?: any) {
    const itemStr = localStorage.getItem(key);

  
    // If the item doesn't exist, return null
    if (!itemStr) {
      return null;
    }
  
    const item = JSON.parse(itemStr);
    const now = new Date();
  
    // Compare the expiry time of the item with the current time
    if (now.getTime() > item.expiry) {
      // If the item is expired, remove it from localStorage and return null
      localStorage.removeItem(key);
      return null;
    }
    return item.value;
  }

  const handleActiveMainMenu = (menuItem: any) => () => {
    if (menuItem.title === 'Products' && !getWithExpiry('pin')?.length) {
      setIsPinModal(true)
      setProductItem(menuItem);
    }
    else {
      setActive(menuItem.title);
      navigate(menuItem.path);
      closeMobileSideBar();
    }
  };


  const handleActiveMainMenu2 = (menuItem: any) => () => {
    // setActive(menuItem.title);
    navigate(menuItem.path);
    closeMobileSideBar();
  };

  const [option, setOption] = React.useState("");

  const handleMove = () => {
    navigate("/dashboard/timing");
  };

  const handleChange2 = (e: any) => {
    setOption(e.target.value);
    if (e.target.value == "other") {
      setTiming(true);
    } else {
      setTiming(false);
    }
  };


  function setWithExpiry(key?: any, value?: any, ttl?: any) {
    const now = new Date();
  
    // 'item' is an object which contains the value and the expiry time
    const item = {
      value: value,
      expiry: now.getTime() + ttl,
    };

    localStorage.setItem(key, JSON.stringify(item));
  }
  

  const verifyPin = (pin?: string) => {
    API.postData(API.sellerUrl + 'mou/verifyProductTabPin', { pin }).then((res)=>{
        if (res?.data?.access) {
          setActive(productItem.title);
          navigate(productItem.path);
          closeMobileSideBar();
          setIsPinModal(false);
          setWithExpiry('pin', pin, 3600000);
        }
        else {
           toast.error("Invalid pin");
        }
    }).catch((err) => {
       toast.error("Something went wrong");
    })
  }

  // main menus content
  const mainSideBarContent = (
    <List sx={{ height: "100%" }}>
      <Box sx={{ ...toggle, display: !checked && sf ? "block" : "none" }}>
        <h1>Auto turn on after</h1>

        <hr />
        <RadioGroup
          aria-labelledby='demo-radio-buttons-group-label'
          defaultValue='2 Hours'
          name='radio-buttons-group'
          onChange={handleChange2}
        >
          <FormControlLabel
            value='2 Hours'
            control={<Radio />}
            label='2 Hours'
          />
          <FormControlLabel
            value='4 Hours'
            control={<Radio />}
            label='4 Hours'
          />
          <FormControlLabel
            value='tomorrow'
            control={<Radio />}
            label='Tomorrrow before first slot'
          />
          <FormControlLabel
            value='other'
            control={<Radio />}
            label='Specific date or time'
          />
          <div
            style={{
              display: timing ? "block" : "none",
              backgroundColor: "whitesmoke",
            }}
          >
            {/* <DateTimePicker
              onChange={(newValue) => {
                setValue(newValue);
              }}
              value={value}
            /> */}
          </div>
          <hr style={{ width: "14rem" }} />
          <h2>Manually turn on</h2>
          <FormControlLabel
            value='later'
            control={<Radio />}
            label='I will turn it on myself'
          />
          <p>
            Shop will be turn off for customers on app untill you turn it back
            on
          </p>
        </RadioGroup>

        <RadioGroup aria-label='time' name='time1'></RadioGroup>

        <div style={{ display: "flex", justifyContent: "space-around" }}>
          <button
            className='yes'
            style={{
              borderColor: "yellow",
              color: "white",
              backgroundColor: "#1d2438",
              borderRadius: "5px",
            }}
            onClick={() => setSf(false)}
          >
            Yes close
          </button>
          <button
            className='yes'
            style={{
              borderColor: "yellow",
              color: "white",
              backgroundColor: "#1d2438",
            }}
            onClick={() => setChecked(true)}
          >
            Cancel
          </button>
        </div>
      </Box>

      <StyledLogo disableRipple>
        <img src='/static/logo/logo.png' alt='Logo' height={23} />
      </StyledLogo>

      <ShopDisableSwitch />
      <PinForProduct open={isPinModal} onClose={() => { setIsPinModal(false) }} verifyPinForProduct={(pin) => {
          verifyPin(pin)
      }}/>

      {/* <Stack sx={{ alignItems: "center" }}>
        <Switch
          checked={checked}
          onChange={handleChange}
          onClick={handleClick}
          inputProps={{ "aria-label": "controlled" }}
        ></Switch>
      </Stack> */}

      <ScrollBar
        style={{ maxHeight: "calc(100% - 50px)", alignContent: "center" }}
      >
        {topMenuList.map((nav, index) => (
          <Fragment key={index}>
            <StyledListItemButton
              className='nav-hover'
              disableRipple
              onClick={handleActiveMainMenu(nav)}
              sx={{
                color: "#FFFFFF",
                backgroundColor:
                  active === nav.title ? "rgba(255, 232, 3, 0.3)" : "#000000",
                background:
                  active === nav.title
                    ? "linear-gradient(90deg, rgba(255,232,3,0.3) 0%, rgba(20,20,20,0.3) 99%)"
                    : "#000000",
                borderRadius: "3rem",
                margin: "5px",
                height: "8vh",
              }}
            >
              <nav.Icon />
              <p style={{ marginLeft: ".5rem" }}>{nav.title}</p>
            </StyledListItemButton>
            <div
              style={{
                visibility: active === "User Profile" ? "visible" : "hidden",
              }}
              onClick={handleMove}
            >
              <p style={{ color: "white" }}>
                {/* {nav?.sub ? (
                  <div>
                    {" "}
                    {nav?.sub?.map((e) => (
                      <>
                        <StyledListItemButton
                          disableRipple
                          onClick={handleActiveMainMenu2(e)}
                          sx={{
                            color: active === e.title ? "#FFFFFF" : "#FFFFFF",
                            backgroundColor: "#3366ff",
                            // active === e.title
                            //   ? "rgba(255, 232, 3, 0.3)"
                            //   : "#000000"
                            borderRadius: "20px",
                            margin: "5px",
                          }}
                        >
                          <nav.Icon />
                          <p style={{ marginLeft: ".5rem" }}>{e.title}</p>
                        </StyledListItemButton>
                      </>
                    ))}{" "}
                  </div>
                ) : null} */}
              </p>
            </div>
          </Fragment>
        ))}
      </ScrollBar>
    </List>
  );

  // for mobile device
  if (downMd) {
    return (
      <Drawer
        anchor='left'
        open={showMobileSideBar}
        onClose={closeMobileSideBar}
        PaperProps={{ sx: { width: 80 } }}
      >
        <Box
          sx={{
            height: "100%",
            display: "flex",
            width: "inherit",
            position: "fixed",
            overflow: "hidden",
            flexDirection: "column",
            boxShadow: (theme) => theme.shadows[1],
            backgroundColor: (theme) => theme.palette.text.primary,
            "& .simplebar-track.simplebar-vertical": { width: 6 },
            "& .simplebar-scrollbar:before": {
              background: (theme) => theme.palette.text.primary,
            },
          }}
        >
          {mainSideBarContent}
        </Box>
      </Drawer>
    );
  }

  return (
    <MainMenu sx={{ alignItems: "center" }}>{mainSideBarContent}</MainMenu>
  );
};

export default DashboardSideBar;
