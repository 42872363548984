import {
  Box,
  ClickAwayListener,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  Switch,
} from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import { styled } from "@mui/material/styles";
import * as React from "react";
import { useState } from "react";
import { toast } from "react-hot-toast";
import { API } from "service/api";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export default function ShopDisableSwitch() {
  const userJSON = localStorage.getItem("user");
  const user = userJSON ? JSON.parse(userJSON) : null;
  const [open, setOpen] = React.useState(false);
  const [showTimePicker, setShowTimePicker] = useState(false);
  const [date, setDate] = useState("");
  let [check, setCheck] = React.useState(
    user?.shopStatus === "open" ? true : false
  );

  const handleChange = () => {
    if (check) {
      setCheck(false);
      setOpen(true);
    } else {
      setCheck(true);
      handleShopOpen();
    }
  };
  //  the dialog if the click was outside of it
  // will still be open
  const handleClickAway = () => {
    setOpen(true);
  };

  const [optionDisable, setOptionDisable] = useState("");
  const handleRadio = (e: any) => {
    if (e.target.value === "custom") {
      setShowTimePicker(true);
      setOptionDisable(e.target.value);
    } else {
      setOptionDisable(e.target.value);
      setShowTimePicker(false);
    }
  };

  const handleShopOpen = () => {
    setDate("");
    const data = {};
    API.putData(API.sellerUrl + `shop/${"open"}`, { ...data })
      .then((res) => {
        toast.success("Successfully Open ");
        const userData = user;
        userData.shopStatus = "open";
        localStorage.setItem("user", JSON.stringify(userData));
      })
      .catch((err) => {
        toast.error("Error Try again");
      });
  };
  const handleShopClose = () => {
    if (optionDisable) {
      let data: any = { type: optionDisable };

      if (optionDisable === "custom") {
        let schedulerInactiveTime = new Date(date).getTime();
        data.schedulerInactiveTime = schedulerInactiveTime;
      }

      API.putData(API.sellerUrl + `shop/${"closed"}`, { ...data })
        .then((res) => {
          toast.success("Successfully Closed ");
          const userData = user;
          userData.shopStatus = "closed";
          localStorage.setItem("user", JSON.stringify(userData));
        })
        .catch((err) => {
          toast.error("Error Try again later");
        });
      setOpen(false);
    } else {
      toast.error("Please Select any Interval or cancel");
    }
  };

  const handleClose = () => {
    setOpen(false);
    const check: any = user.shopStatus === "closed" ? false : true;
    setCheck(check);
  };
  return (
    <div>
      <Stack sx={{ alignItems: "center" }}>
        <Switch
          inputProps={{ "aria-label": "controlled" }}
          checked={check}
          onChange={handleChange}
        ></Switch>
      </Stack>

      <ClickAwayListener onClickAway={handleClickAway}>
        <BootstrapDialog
          // onClose={handleClose}
          aria-labelledby='customized-dialog-title'
          open={open}
        >
          <DialogContent dividers>
            <Box>
              <h3> Auto turn on after</h3>
              <RadioGroup
                aria-labelledby='demo-radio-buttons-group-label'
                defaultValue='2 Hours'
                name='radio-buttons-group'
                onChange={handleRadio}
              >
                <FormControlLabel
                  value='tomorrow'
                  control={<Radio />}
                  label='Tomorrrow before first slot'
                />
                <div style={{ display: "flex", alignItems: "center" }}>
                  <FormControlLabel
                    value='custom'
                    control={<Radio />}
                    label='Specific date or time'
                  />
                  {showTimePicker ? (
                    <label htmlFor='datePicker'>
                      <input
                        id='datePicker'
                        style={{
                          height: "4vh",
                          borderRadius: "0.5rem",
                          outlineColor: "#3b97c6",
                        }}
                        type='datetime-local'
                        onChange={(e) => setDate(e.target.value)}
                      />
                    </label>
                  ) : null}
                </div>
                <hr />
                <h3>Manually turn on</h3>
                <FormControlLabel
                  value='manual'
                  control={<Radio />}
                  label='I will turn it on myself'
                />
                <p>
                  Shop will be closed for customers on app untill you turn it
                  back on
                </p>
              </RadioGroup>
            </Box>
          </DialogContent>
          <DialogActions
            sx={{ display: "flex", justifyContent: "space-evenly" }}
          >
            <Button
              sx={{ backgroundColor: "yellow", color: "black" }}
              variant='contained'
              autoFocus
              onClick={handleShopClose}
            >
              Disable
            </Button>
            <Button
              sx={{ backgroundColor: "red" }}
              variant='contained'
              autoFocus
              onClick={handleClose}
            >
              Cancel
            </Button>
          </DialogActions>
        </BootstrapDialog>
      </ClickAwayListener>
    </div>
  );
}
