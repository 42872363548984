/* eslint-disable jsx-a11y/img-redundant-alt */
import { Button, Switch } from "@mui/material";
import { Fragment, useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { FaEye } from "react-icons/fa";
import { useNavigate, useParams } from "react-router-dom";
import { API } from "service/api";
import Swal from "sweetalert2";
import style from "./RiderDetail.module.css";

export default function RiderDetail() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const { id } = useParams();

  const [rider, setRider] = useState<any>({});

  let isMounted: any = true;

  useEffect(() => {
    fetchData();

    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      isMounted = false;
    };
  }, []);

  const fetchData = async () => {
    setLoading(true);
    try {
      const result = await API.getData(
        API.sellerUrl + "profile/rider-profiles/" + id
      );
      if (isMounted) {
        setRider(result.data);
        setLoading(false);
      }
    } catch (error) {
      if (isMounted) {
        toast.error("Cannot get Data");
        setLoading(false);
      }
    }
  };

  const handleGoBack = () => {
    navigate(-1);
  };

  const handleApproveRider = () => {
    Swal.fire({
      title: "Approve Rider",
      text: "Are you sure you want to Approve ?",
      icon: "info",
      confirmButtonText: "OK",
      confirmButtonColor: "blue",
      cancelButtonText: "Cancel",
      showCancelButton: true,
      allowOutsideClick: false,
    }).then((result) => {
      if (result.isConfirmed) {
        // handle OK button click
        approveRider();
      } else if (result.isDismissed) {
        // handle Cancel button click or modal close
        return;
      }
    });
  };

  const approveRider = async () => {
    try {
      const result = await API.putData(
        API.sellerUrl + "profile//approve/" + id,
        { status: true }
      );
      fetchData();
    } catch (error) {
      if (isMounted) {
        toast.error("Cannot get Data");
      }
    }
  };

  return (
    <div className={style.container}>
      <div className={style.title_container}>
        <h3>Rider Details</h3>

        <div className={style.optionBtn}>
          {!rider?.sellerApproved && (
            <Button onClick={handleApproveRider}>Approve</Button>
          )}

          <Button onClick={handleGoBack}>Back</Button>
        </div>
      </div>
      <div className={style.content}>
        <div>
          <table className={style.table}>
            <tbody>
              <tr>
                <td>Name:</td>
                <td>{rider?.name}</td>
              </tr>
              <tr>
                <td>Contact:</td>
                <td>{rider?.contact}</td>
              </tr>
              <tr>
                <td>Status:</td>
                <td>
                  <Switch checked={rider?.status === "active"} />
                </td>
              </tr>
              <tr>
                <td>Available:</td>
                <td>
                  <Switch checked={rider?.available === true} />
                </td>
              </tr>
              <tr>
                <td>Registration:</td>
                <td>{new Date(rider?.createdAt).toDateString()}</td>
              </tr>
              <tr>
                <td>KYC:</td>
                <td>{rider?.kycStage}</td>
              </tr>
              <tr>
                <td>Bank Account No:</td>
                <td>{rider?.bankDetails?.accountNumber}</td>
              </tr>
              <tr>
                <td>IFSC:</td>
                <td>{rider?.bankDetails?.ifsc}</td>
              </tr>
              <tr>
                <td>PAN:</td>
                <td>{rider?.panCardDetails?.panCardNumber}</td>
              </tr>
            </tbody>
          </table>

          {rider?.addresses?.map((address: any) => (
            <div key={address._id}>
              <h4>Address:</h4>
              <table className={style.table}>
                <tbody>
                  <tr>
                    <td>City:</td>
                    <td>{address.city}</td>
                  </tr>
                  <tr>
                    <td>Line 1:</td>
                    <td>{address.line1}</td>
                  </tr>
                  <tr>
                    <td>Line 2:</td>
                    <td>{address.line2}</td>
                  </tr>
                  <tr>
                    <td>Pincode:</td>
                    <td>{address.pincode}</td>
                  </tr>
                  <tr>
                    <td>State:</td>
                    <td>{address.state}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          ))}
        </div>
        <div className={style.active_order_table_container}>
          <h4 className={style.active_order_table_title}>Active Orders</h4>
          <table className={style.active_order_table}>
            <thead>
              <th>Order Id</th>
              <th>Placed Time</th>
              <th>Buyer</th>
              <th>Buyer Contact</th>
              <th>Payment Mode</th>
              <th>Amt.</th>
            </thead>
            <tbody>
              {rider?.activeOrders &&
                rider?.activeOrders?.map((item: any) => {
                  return (
                    <Fragment key={item._id}>
                      <tr>
                        <td>{item._id.slice(-5).toUpperCase()}</td>
                        <td>{new Date(item.placed.date).toLocaleString()}</td>
                        <td>{item.buyer.name}</td>
                        <td>{item.buyer.contact}</td>
                        <td>{item.paymentMode}</td>
                        <td>
                          {item?.order?.totalAmt +
                            item?.deliveryMode?.charges -
                            item?.walletUse -
                            item?.rewardUse}
                        </td>
                      </tr>
                    </Fragment>
                  );
                })}
            </tbody>
          </table>
        </div>
      </div>

      {rider?.kycDocument && (
        <div className={style.document_img_container}>
          {rider.kycDocument.profilePhoto && (
            <div>
              <div className={style.document_header}>
                <h4>Profile Photo </h4>
                <FaEye
                  onClick={(e) => {
                    e.stopPropagation();
                    window.open(rider.kycDocument.profilePhoto[0], "_blank");
                  }}
                  style={{ fontSize: "1rem", cursor: "pointer" }}
                />
              </div>
              {rider.kycDocument.profilePhoto.map((url: string) => (
                <img
                  key={url}
                  src={url}
                  alt='Profile Photo'
                  className={style.document_image}
                  onClick={(e) => {
                    e.stopPropagation();
                    window.open(url, "_blank");
                  }}
                />
              ))}
            </div>
          )}
          {rider.kycDocument.aadhaarCard && (
            <div>
              <div className={style.document_header}>
                <h4>Aadhaar Card </h4>
                <FaEye
                  onClick={(e) => {
                    e.stopPropagation();
                    window.open(rider.kycDocument.aadhaarCard[0], "_blank");
                  }}
                  style={{ fontSize: "1rem", cursor: "pointer" }}
                />
              </div>
              {rider.kycDocument.aadhaarCard.map((url: string) => (
                <img
                  key={url}
                  src={url}
                  alt='Aadhaar Card'
                  className={style.document_image}
                  onClick={(e) => {
                    e.stopPropagation();
                    window.open(url, "_blank");
                  }}
                />
              ))}
            </div>
          )}
          {rider.kycDocument.drivingLicense && (
            <div>
              <div className={style.document_header}>
                <h4>Driving License </h4>
                <FaEye
                  onClick={(e) => {
                    e.stopPropagation();
                    window.open(rider.kycDocument.drivingLicense[0], "_blank");
                  }}
                  style={{ fontSize: "1rem", cursor: "pointer" }}
                />
              </div>
              {rider.kycDocument.drivingLicense.map((url: string) => (
                <img
                  key={url}
                  src={url}
                  alt='Driving License'
                  className={style.document_image}
                  onClick={(e) => {
                    e.stopPropagation();
                    window.open(url, "_blank");
                  }}
                />
              ))}
            </div>
          )}
        </div>
      )}
    </div>
  );
}
