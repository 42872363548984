import { atom } from "recoil";

export const selectCatID = atom({
  key: "selectCatID",
  default: "",
});

export const selectCatLevel = atom({
  key: "selectCatLevel",
  default: 2,
});

export const allCategory = atom({
  key: "allCategory",
  default: [],
});
