import ArrowDownwardOutlinedIcon from "@mui/icons-material/ArrowDownwardOutlined";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import TableCell from "@mui/material/TableCell";
import AuthContext, { AuthState } from "contexts/JWTAuthContext";
import { useContext, useState } from "react";
import { useDropzone } from "react-dropzone";
import toast from "react-hot-toast";
import GridLoader from "react-spinners/GridLoader";
import { API } from "service/api";
// import sampleProductTemplate from "./Product_Excel_Template.xlsx";
import Swal from "sweetalert2";
import sample from "./sample.png";
const BulkAddProducts = () => {
  const { user }: AuthState = useContext(AuthContext);
  // console.log(user, "user");
  const [wrongProduct, setWrong] = useState(false);
  const sellerId = user?._id;
  const [sampl, setSampl] = useState(false);
  const [fileName, setFileName] = useState("");
  const [choosefile, setChooseFile] = useState({});
  const [loading, setLoading] = useState(false);
  const handleFileUpload = async (event: any) => {
    const file = event.target.files[0];
    // console.log(file, "upload");
    setFileName(file?.name);
    setChooseFile(file);

    // console.log("file", file);

    // console.log(result);
  };
  // const Accept = ["application/vnd.ms-excel", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"]
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    // accept: Accept,
    onDrop: (acceptedFiles: File[]) => {
      handleFileUpload(acceptedFiles[0]);
    },
  });

  const handleUploadExcel = async () => {
    setLoading(true);
    const formData = new FormData();
    formData.append("file", choosefile as Blob);
    formData.append("sellerId", sellerId);
    // console.log(formData);
    let result = await API.postData(API.sellerUrl + "upload", formData)
      .then((res: any) => {
        toast.success("Excel uploaded Successfully", { duration: 5000 });
        if (res.status === "SUCCESS") {
          setLoading(false);
          setFileName("");
          console.log(res.data);
          const data = res.data;
          Swal.fire({
            icon: "info",
            title: "File Upload Summary",
            html: `
                <p><strong>Message:</strong> ${data.message}</p>
                <p><strong>File Type:</strong> ${data.fileType}</p>
                <p><strong>Uploaded Products:</strong> ${data.UploadedProducts}</p>
                <p><strong>Updated Products Count:</strong> ${data.updatedProductsCount}</p>
                <p><strong>Newly Added Products:</strong> ${data.newlyAddedProducts}</p>
            `,
          });
        }
      })
      .catch((e) => {
        alert(e);
        setLoading(false);
        toast.error("Error try again after some time...");
        // console.log(e.message);
        setWrong(true); // Set wrongProduct state to true if upload fails
      });
  };

  // const handleSampleClick = (event: any) => {
  //   event.stopPropagation();
  //   setSampl(true);
  // };

  const handleSampleClick = (event: any) => {
    event.stopPropagation();
  };

  const handleComponentClick = () => {
    setSampl(false);
  };

  // const handleDownloadExcel = async () => {
  //   try {
  //     let res = await axios.get(
  //       "http://localhost:3888/api/customer/seller/excel/seller-products/60e7f1139dc49c13d80d50f8",
  //       {
  //         responseType: "blob",
  //       }
  //     );

  //     const url = window.URL.createObjectURL(new Blob([res.data as BlobPart]));
  //     const link = document.createElement("a");
  //     link.href = url;
  //     link.setAttribute("download", `product.xlsx`);
  //     document.body.appendChild(link);
  //     link.click();
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };

  async function handleDownload(event: any) {
    event.stopPropagation();
    const url = API.sellerUrl + `excel/seller-products/${sellerId}`;

    try {
      const excelRes = await API.getData(url, {}, { responseType: "blob" });

      const link = document.createElement("a");
      link.href = URL.createObjectURL(excelRes);
      link.download = `prods.xlsx`;
      link.click();
      URL.revokeObjectURL(link.href);
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <div onClick={handleComponentClick}>
      <Box
        sx={{
          display: sampl ? "block" : "none",
          zIndex: "3",
          position: "absolute",
          boxShadow: "3",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <img
          style={{ height: "20rem", width: "70rem" }}
          src={sample}
          alt='klhj'
        />
      </Box>
      <h1>Bulk Add Products</h1>

      <Box
        sx={{
          position: "absolute",
          display: wrongProduct ? "block" : "none",
          // height:'35rem',
          width: "45%",
          backgroundColor: "white",
          zIndex: "1",
          padding: "2%",
          top: "12rem",
          left: "29rem",
          boxShadow: "3",
          bottom: "8rem",
        }}
      >
        <h4>
          Excel file added is not according to the format required. Refer&nbsp;
          <span
            style={{
              color: "#21A2FF",
              cursor: "pointer",
              fontFamily: "Poppins",
              textDecoration: "underline",
            }}
            onClick={handleSampleClick}
          >
            Sample Template
          </span>{" "}
          once and make changes.
        </h4>

        <div style={{ color: "red" }}>
          1 Errors were found in your imported xls file
        </div>
        <div
          style={{
            width: "100%",
            borderRadius: "10%",
            marginTop: "3%",
          }}
        >
          <table
            style={{
              borderCollapse: "collapse",
            }}
          >
            <thead
              style={{
                backgroundColor: "rgba(230, 66, 66, 0.2)",
                marginTop: "3%",
                // borderTopLeftRadius: "5%",
                width: "100%",
              }}
            >
              <TableCell
                style={{
                  // borderTopLeftRadius: "5%",
                  // borderTopRightRadius: "5%",
                  border: "1px solid #c0c0c0",
                  // borderCollapse: "collapse",
                }}
              >
                Rows
              </TableCell>
              <TableCell
                style={{
                  border: "1px solid #c0c0c0",
                  borderCollapse: "collapse",
                }}
              >
                Columns
              </TableCell>
              <TableCell
                style={{
                  // borderTopRightRadius: "5%",
                  border: "1px solid #c0c0c0",
                  borderCollapse: "collapse",
                }}
              >
                Error found
              </TableCell>
            </thead>
            <tr>
              <TableCell
                style={{
                  border: "1px solid #c0c0c0",
                  borderCollapse: "collapse",
                }}
              >
                1
              </TableCell>
              <TableCell
                style={{
                  border: "1px solid #c0c0c0",
                  borderCollapse: "collapse",
                }}
              >
                Multiple
              </TableCell>
              <TableCell
                style={{
                  border: "1px solid #c0c0c0",
                  borderCollapse: "collapse",
                }}
              >
                Invalid Header Present: working hours -empty -empty-1
              </TableCell>
            </tr>
            <tr>
              <td>
                <Button
                  sx={{
                    position: "absolute",
                    top: "80%",
                    left: "45%",
                    backgroundColor: "black",
                    color: "white",
                  }}
                  onClick={() => setWrong(false)}
                >
                  OK
                </Button>
              </td>
            </tr>
          </table>
        </div>
      </Box>

      <Box
        sx={{
          position: "fixed",
          width: "70rem",
          height: "35rem",
          left: "16rem",
          top: "8rem",
          background: "#FFFFFF",
          // box-shadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
          borderRadius: "20px",
          padding: "4%",
        }}
      >
        <h4>Upload Excel File</h4>
        <p>
          View{" "}
          <span
            style={{
              color: "#21A2FF",
              textDecoration: "underline",
              cursor: "pointer",
            }}
            onClick={handleSampleClick}
          >
            Excel sheet Template
          </span>{" "}
          to about the format required
        </p>

        {/* <Button onClick={handleDownloadExcel}>Download Product Excel</Button> */}
        {/* <Button onClick={handleDownload}>Download Product Excel</Button> */}

        <Button
          sx={{
            marginLeft: "75%",
            backgroundColor: "rgba(33, 162, 255, 0.1)",
            borderColor: "#21A2FF",
            color: "#21A2FF",
            fontWeight: "800",
            padding: "1%",
            cursor: "pointer",
          }}
          onClick={handleDownload}
          variant='outlined'
          endIcon={<FileDownloadOutlinedIcon />}
        >
          Download Product Excel
        </Button>
        {/* <a
          href={sampleProductTemplate}
          download='Sample-Product-Template'
          target='_blank'
          rel='noreferrer'
        >
          <button>Download .xlsx file</button>
        </a> */}
        <Box
          sx={{
            position: "absolute",
            marginTop: "3%",
            height: "15rem",
            width: "60rem",
            // background: 'red',
            // alignItems:'center',
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderStyle: "dashed",
            // box-shadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
            borderRadius: "4px",
            // padding:'4%'
          }}
        >
          {loading ? (
            <GridLoader color='#3692d6' />
          ) : (
            <>
              <input
                type='file'
                id='files'
                accept='application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,text/csv'
                onChange={handleFileUpload}
                style={{ display: "none" }}
              />

              <div>
                {fileName ? <h3>{fileName}</h3> : null}
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Button
                    sx={{
                      // marginLeft: "75%",
                      backgroundColor: "rgba(33, 162, 255, 0.1)",
                      borderColor: "#21A2FF",
                      color: "#21A2FF",
                      fontWeight: "800",
                      padding: "5%",
                      cursor: "pointer",
                    }}
                    variant='outlined'
                    // startIcon={<FileUploadOutlinedIcon />}
                    onClick={() => {
                      const input = document.getElementById("files");
                      if (input) {
                        input.click();
                      }
                    }}
                  >
                    {!fileName ? "Upload Excel File" : "Change File"}
                  </Button>
                  {fileName ? (
                    <Button
                      sx={{
                        // marginLeft: "75%",
                        backgroundColor: "rgba(33, 162, 255, 0.1)",
                        borderColor: "#21A2FF",
                        color: "#21A2FF",
                        fontWeight: "800",
                        padding: "5%",
                        cursor: "pointer",
                      }}
                      variant='outlined'
                      startIcon={<FileUploadOutlinedIcon />}
                      onClick={handleUploadExcel}
                    >
                      Upload
                    </Button>
                  ) : null}
                </div>

                <div {...getRootProps()}>
                  <input {...getInputProps()} />
                  {isDragActive ? (
                    <div>
                      <p>
                        <ArrowDownwardOutlinedIcon style={{ color: "grey" }} />
                      </p>
                      <p> drop the file here...</p>
                    </div>
                  ) : (
                    <div style={{ display: "flex" }}>
                      <p>
                        <ArrowDownwardOutlinedIcon style={{ color: "grey" }} />
                      </p>
                      <p> &nbsp; or drag and drop your file here...</p>
                    </div>
                  )}
                </div>
              </div>
            </>
          )}
        </Box>
      </Box>
    </div>
  );
};

export default BulkAddProducts;
