import { useState } from "react";

export default function RaiseOrder() {
    // Get user id from local storage
    const userJSON = localStorage.getItem("user");
    const user = userJSON ? JSON.parse(userJSON) : null;
    const [loading, setLoading] = useState(false);
    const [skuList, setSkuList] = useState([{ sku: "", qty: 0 }]);
    const [raisedOrders, setRaisedOrders] = useState([]);

    const handleSkuChange = (index:any, field:any, value:any) => {
        const newSkuList = [...skuList];
        // newSkuList[index][field] = value;
        setSkuList(newSkuList);
    };

    const addSku = () => {
        setSkuList([...skuList, { sku: "", qty: 0 }]);
    };

    const removeSku = (index : any) => {
        const newSkuList = skuList.filter((_, i) => i !== index);
        setSkuList(newSkuList);
    };

    const raiseOrder = () => {
        setLoading(true);
        // Simulate an API call
        setTimeout(() => {
            // setRaisedOrders([...raisedOrders, { user: any, skuList: any }]);
            setSkuList([{ sku: "", qty: 0 }]);
            setLoading(false);
        }, 1000);
    };

    // Return JSX elements to render
    return (
        <div>
            <h1>Raise Order</h1>
            {skuList.map((item, index) => (
                <div key={index}>
                    <input
                        type="text"
                        placeholder="SKU"
                        value={item.sku}
                        onChange={(e) => handleSkuChange(index, "sku", e.target.value)}
                    />
                    <input
                        type="number"
                        placeholder="Quantity"
                        value={item.qty}
                        onChange={(e) => handleSkuChange(index, "qty", e.target.value)}
                    />
                    <button onClick={() => removeSku(index)}>Remove</button>
                </div>
            ))}
            <button onClick={addSku}>Add SKU</button>
            <button onClick={raiseOrder} disabled={loading}>Raise Order</button>
            {loading && <p>Loading...</p>}
            <h2>Raised Orders</h2>
            <ul>
                {raisedOrders.map((order, index) => (
                    <li key={index}>
                        <h3>Order {index + 1}</h3>
                        <p>User ID: </p>
                        <ul>
                            {((item:any, i:any) => (
                                <li key={i}>
                                    {item.sku}: {item.qty}
                                </li>
                            ))}
                        </ul>
                    </li>
                ))}
            </ul>
        </div>
    );
}