import pkg from "../../package.json";
import config from "./config";
export const environment = {
  appName: config.appName,
  version: pkg.version,
  apiUrl: config.apiUrl,
  socketUrl: config.socketUrl,
  // apiDevUrl: config.apiDevUrl,
  // socketDevUrl: config.socketDevUrl,
  // rp_key: config.rp_key
};
