import AccessAlarmIcon from "@mui/icons-material/AccessAlarm";
import Icons from "icons/sidebar";

const index = [
  {
    title: "Orders",
    Icon: Icons.OrdersIcon,
    path: "/dashboard",
  },
  // {
  //   title: "Products",
  //   Icon: Icons.DashboardIcon,
  //   path: "/dashboard/user-products",
  // },

  {
    title: "Products",
    Icon: Icons.DashboardIcon,
    path: "/dashboard/products",
  },
  // {
  //   title: "Raise Order",
  //   Icon: Icons.LoginIcon,
  //   path: "/dashboard/raise-order",
  // },
  {
    title: "Stats",
    Icon: Icons.ProductsIcon,
    path: "/dashboard/stats",
  },

  // {
  //   title: "User Grid",
  //   Icon: Icons.UserGridIcon,
  //   path: "/dashboard/user-grid",
  // },
  // {
  //   title: "User List",
  //   Icon: Icons.UserManagementIcon,
  //   path: "/dashboard/user-list",
  // },
  // {
  //   title: "Add user",
  //   Icon: Icons.AddUserIcon,
  //   path: "/dashboard/add-user",
  // },
  // {
  //   title: "Login",
  //   Icon: Icons.LoginIcon,
  //   path: "/login",
  // },

  {
    title: "User Profile",
    Icon: Icons.UserProfileIcon,
    path: "/dashboard/user-profile",
    // sub: [

    //   // {
    //   //   title: "Shop Timing",
    //   //   Icon: Icons.UserProfileIcon,
    //   //   path: "/dashboard/timing",
    //   // },
    // ],
  },
  {
    title: "Shop Timing",
    Icon: AccessAlarmIcon,
    path: "/dashboard/timing",
  },
];

export default index;
