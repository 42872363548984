import AuthGuard from "components/authentication/AuthGuard";
import GuestGuard from "components/authentication/GuestGuard";
import DashboardLayout from "components/Layouts/DashboardLayout";
import LoadingScreen from "components/LoadingScreen";
import ShopTiming from "pages/dashboards/ShopTiming";
import OrderScreen from "pages/orders/OrderScreen";
import BulkAddProducts from "pages/productList/BulkAddProducts";
import Product from "pages/Products/ProductPage/Products";
import RaiseOrder from "pages/Products/RaiseOrderPage/RaiseOrder";
import RiderDetail from "pages/userProfile/myRiders/RiderDetail";
import { FC, lazy, LazyExoticComponent, Suspense } from "react";
import { Navigate } from "react-router-dom";
const Loadable = (Component: LazyExoticComponent<FC>) => (props: any) =>
  (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );

// authentication pages
const Login = Loadable(
  lazy(() => import("./pages/authentication/Login")));
const Register = Loadable(
  lazy(() => import("./pages/authentication/Register"))
);
const ForgetPassword = Loadable(
  lazy(() => import("./pages/authentication/ForgetPassword"))
);

// Dashboard pages
const DashboardSaaS = Loadable(lazy(() => import("./pages/dashboards/Stats")));

// user profile
const UserProfile = Loadable(lazy(() => import("./pages/UserProfile")));
// user products
const ProductDetails = Loadable(
  lazy(() => import("./pages/productList/ProductDetails"))
);
const ProductListItem = Loadable(
  lazy(() => import("./pages/productList/productItem"))
);

// user management
const UserList = Loadable(
  lazy(() => import("./pages/userManagement/UserList"))
);
const UserGrid = Loadable(
  lazy(() => import("./pages/userManagement/UserGrid"))
);
const AddNewUser = Loadable(
  lazy(() => import("./pages/userManagement/AddNewUser"))
);

// error
const Error = Loadable(lazy(() => import("./pages/404")));

// routes
const routes = [
  {
    path: "/",
    element: <Navigate to='dashboard' />,
  },
  {
    path: "login",
    element: (
      <GuestGuard>
        <Register />
      </GuestGuard>
    ),
  },
  {
    path: "dashboard",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "stats",
        element: <DashboardSaaS />,
      },
      {
        path: "user-profile",
        element: <UserProfile />,
      },
      {
        path: "user-products",
        element: <ProductDetails />,
      },
      {
        path: "user-products-add",
        element: <BulkAddProducts />,
      },

      {
        path: "",
        element: <OrderScreen />,
      },

      {
        path: "user-list",
        element: <UserList />,
      },
      {
        path: "user-grid",
        element: <UserGrid />,
      },
      {
        path: "add-user",
        element: <AddNewUser />,
      },
      {
        path: "timing",
        element: <ShopTiming />,
      },
      {
        path: "products",
        element: <Product />,
      },
      {
        path: "raise-order",
        element: <RaiseOrder />,
      },
      {
        path: "rider-details/:id",
        element: <RiderDetail />,
      },
    ],
  },
  {
    path: "*",
    element: <Navigate to='/dashboard' />,
  },
];

export default routes;
