import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import Stack from "@mui/material/Stack";
import FlexBox from "components/FlexBox";
import { useState } from 'react';

// Define a type for the props
interface DateRangePickerProps {
  onDateChange: (date: string, which: 'start' | 'end') => void;
}

// Define a type for the calendar visibility state
interface CalendarVisibility {
  start: boolean;
  end: boolean;
}

function DateRangePicker({ onDateChange }: DateRangePickerProps) {
  const [calendarVisible, setCalendarVisible] = useState<CalendarVisibility>({ start: false, end: false });
  const today = new Date().toISOString().split('T')[0];

  const toggleCalendar = (calendar: keyof CalendarVisibility) => {
    setCalendarVisible(prevState => ({ ...prevState, [calendar]: !prevState[calendar] }));
  };

  const handleDateChange = (date: string, which: 'start' | 'end') => {
    onDateChange(date, which);
    toggleCalendar(which);  // Close the calendar after selecting the date
  };

  return (
    <>
      
      <FlexBox
              flexDirection='row'
              justifyContent='space-between'
              padding='1rem'
              alignItems='center'
            >
      <Stack direction='row' spacing={2}>
      <div>
        <label>Start Date:</label>
        <CalendarMonthIcon onClick={() => toggleCalendar('start')} sx={{ "&:hover": { cursor: "pointer" } }} />
        <input
          type="date"
          defaultValue={today}
          onChange={(e) => handleDateChange(e.target.value, 'start')}
          style={{ display: calendarVisible.start ? "block" : "none" }}
        />
      </div>
      <div>
        <label>End Date:</label>
        <CalendarMonthIcon onClick={() => toggleCalendar('end')} sx={{ "&:hover": { cursor: "pointer" } }} />
        <input
          type="date"
          defaultValue={today}
          onChange={(e) => handleDateChange(e.target.value, 'end')}
          style={{ display: calendarVisible.end ? "block" : "none" }}
        />
         </div>
        </Stack>
        </FlexBox>
     
    </>
  );
}

export default DateRangePicker;
