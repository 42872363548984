import LoadingScreen from "components/LoadingScreen";
import {
  createContext,
  ReactNode,
  useEffect,
  useReducer,
  useState,
} from "react";
import { API } from "service/api";
import axios from "utils/axios";

// All types
// =============================================
export type ActionMap<M extends { [index: string]: any }> = {
  [Key in keyof M]: M[Key] extends undefined
    ? {
        type: Key;
      }
    : {
        type: Key;
        payload: M[Key];
      };
};

export type AuthUser = null | Record<string, any>;

export type AuthState = {
  isAuthenticated: boolean;
  isInitialized: boolean;
  user: AuthUser;
};

enum Types {
  Init = "INIT",
  Login = "LOGIN",
  Logout = "LOGOUT",
  Register = "REGISTER",
}

type JWTAuthPayload = {
  [Types.Init]: {
    isAuthenticated: boolean;
    user: AuthUser;
  };
  [Types.Logout]: undefined;
  [Types.Login]: { user: AuthUser };
  [Types.Register]: { user: AuthUser };
};

type JWTActions = ActionMap<JWTAuthPayload>[keyof ActionMap<JWTAuthPayload>];
// ================================================

const initialState: AuthState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
};

const isValidToken = (accessToken: string) => {
  return true;
  // if (!accessToken) return false;
  // const decodedToken = jwtDecode<{ exp: number }>(accessToken);
  // const currentTime = Date.now() / 1000;
  // return decodedToken.exp > currentTime;
};

const setSession = (accessToken: string | null, user?: any) => {
  // (accessToken: string | null)
  if (accessToken) {
    localStorage.setItem("user", JSON.stringify(user));
    localStorage.setItem("accessToken", accessToken);

    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  } else {
    // localStorage.removeItem("accessToken");
    localStorage.removeItem("accessToken");
    localStorage.removeItem("user");
    delete axios.defaults.headers.common.Authorization;
  }
};

const reducer = (state: AuthState, action: JWTActions) => {
  switch (action.type) {
    case "INIT": {
      return {
        isInitialized: true,
        user: action.payload.user,
        isAuthenticated: action.payload.isAuthenticated,
      };
    }
    case "LOGIN": {
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user,
      };
    }
    case "LOGOUT": {
      return {
        ...state,
        user: null,
        isAuthenticated: false,
      };
    }
    case "REGISTER": {
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user,
      };
    }

    default: {
      return state;
    }
  }
};

const AuthContext = createContext({
  ...initialState,
  method: "JWT",
  login: (input: any) => Promise.resolve(),
  logout: () => {},
  register: (email: string, password: string, username: string) =>
    Promise.resolve(),
});

// props type
type AuthProviderProps = {
  children: ReactNode;
};

export const AuthProvider = ({ children }: AuthProviderProps) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [input, setInput] = useState({ contact: "", otp: "" });

  const login = async (input: any) => {
    const response = await API.postData(API.authUrl + "otp/verify", input);
    //@ts-ignore
    // console.log(response.data);
    const { token, seller } = response.data;
    // console.log("vivek" + seller.id);
    setSession(token, seller);
    dispatch({
      type: Types.Login,
      payload: {
        user: seller,
      },
    });
  };

  const register = async (
    email: string,
    username: string,
    password: string
  ) => {
    // const response = await axios.post("/api/auth/register", {
    //   email,
    //   username,
    //   password,
    // });

    const response = await axios.post(API.authUrl + "otp/verify", {
      otp: input.otp,
      contact: input.contact,
    });

    // @ts-ignore
    const { accessToken, user } = response.data;
    setSession(accessToken);

    // console.log(response.data);
    dispatch({
      type: Types.Register,
      payload: {
        user,
      },
    });
  };

  const logout = () => {
    setSession(null);
    dispatch({ type: Types.Logout });
  };

  useEffect(() => {
    (async () => {
      try {
        // const accessToken = window.localStorage.getItem("accessToken");
        const accessToken = window.localStorage.getItem("accessToken");
        const user = JSON.parse(localStorage.getItem("user") || "{}");
        // console.log(accessToken, user);

        if (accessToken) {
          setSession(accessToken, user);

          // const response = await axios.get("/api/auth/profile");
          // //@ts-ignore
          // const { user } = response.data;

          dispatch({
            type: Types.Init,
            payload: {
              user: user,
              isAuthenticated: true,
            },
          });
        } else {
          dispatch({
            type: Types.Init,
            payload: {
              user: null,
              isAuthenticated: false,
            },
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: Types.Init,
          payload: {
            user: null,
            isAuthenticated: false,
          },
        });
      }
    })();
  }, []);

  if (!state.isInitialized) {
    return <LoadingScreen />;
  }

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: "JWT",
        login,
        logout,
        register,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
