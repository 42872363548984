import { CssBaseline, ThemeProvider } from "@mui/material";
import { StyledEngineProvider } from "@mui/material/styles";
// import AuthContext, { AuthState } from "contexts/JWTAuthContext";
// import { getToken } from "firebase/messaging";
import { FC, useEffect } from "react";
import { Toaster } from "react-hot-toast";
import { useRoutes } from "react-router-dom";
// import { messaging } from "./firebase";
import { RecoilRoot } from "recoil";
import routes from "./routes";
import { ukoTheme } from "./theme";

const App: FC = () => {
  const allPages = useRoutes(routes);
  // const token = localStorage.getItem("accessToken");
  // const { user }: AuthState = useContext(AuthContext);
  // App theme
  const appTheme = ukoTheme();

  // toaster options
  const toasterOptions = {
    style: {
      fontWeight: 500,
      fontFamily: "'Montserrat', sans-serif",
    },
  };

  //prevent tab closed
  useEffect(() => {
    const confirmClose = (e: any) => {
      e.preventDefault();
      e.returnValue = ""; // This is necessary for older browsers
      return "Are you sure you want to leave this page? You wont be able to receive orders";
    };

    window.addEventListener("beforeunload", confirmClose);

    return () => {
      window.removeEventListener("beforeunload", confirmClose);
    };
  }, []);

  return (
    <StyledEngineProvider injectFirst>
      <RecoilRoot>
        <ThemeProvider theme={appTheme}>
          <CssBaseline />
          <Toaster toastOptions={toasterOptions} />
          {allPages}
        </ThemeProvider>
      </RecoilRoot>
    </StyledEngineProvider>
  );
};

export default App;
