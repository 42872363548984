import { Button, Modal } from '@mui/material';
import { default as Box } from "@mui/material/Box";
import TextField from '@mui/material/TextField';
import { useState } from 'react';


interface PinForProductProps {
    open: boolean;
    onClose: () => void;
    verifyPinForProduct: (e?: any)=>void;
  }

const PinForProduct: React.FC<PinForProductProps> = ({ open, onClose, verifyPinForProduct}) => {



    const [pin, setPins] = useState('')

    const handleChange = (event?: any) => {
         setPins(event.target.value);
    }

    return (
      <Modal key='yhagsjah' open={open} onClose={onClose}>
        <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', bgcolor: 'background.paper', boxShadow: 24, p: 4 }}>
            <div style={{ display: 'flex', flexDirection: 'column', gap: '6px' }}>
            <b>
                Pin For Product Tab Access
            </b>
            <div>
            <TextField
      label="Enter 6-digit PIN"
      type='Password'
      variant="outlined"
      value={pin}
      onChange={handleChange}
      inputProps={{
        maxLength: 6,
        pattern: "\\d*",
        style: { textAlign: 'center', fontSize: '24px', letterSpacing: '10px' }
      }}
      helperText={pin.length < 6 ? 'Please enter 6 digits' : ''}
    />
            </div>
            <Button onClick={() => { verifyPinForProduct(pin) }}>
                Verify Pin
            </Button>
            </div>
        </Box>
      </Modal>
    );
  };

  export default PinForProduct;