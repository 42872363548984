import { Button } from "@mui/material";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { useRef } from "react";
import { toast } from "react-hot-toast";
import { BsFillCameraFill, BsFillImageFill } from "react-icons/bs";
import { GrFormClose } from "react-icons/gr";
import ReactPlayer from "react-player";
import Style from "./UploadImageModal.module.css";
const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 2,
};

export default function UploadImageModal({
  setOpen,
  open,
  dummyImageProduct,
  productPhotos,
  setProductPhotos,
}: any) {
  // const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const imageUploadRef = useRef<HTMLInputElement>(null);

  function handleDeleteImage(item: any) {
    setProductPhotos(productPhotos.filter((elm: any) => elm._id !== item._id));
  }

  const handleImageChange = (event: any) => {
    const files = event.target.files;
    const newImage = {
      _id: Math.random(),
      thumb: URL.createObjectURL(files?.[0]),
      file: files?.[0],
    };

    if (productPhotos?.length && productPhotos.length >= 4) {
      // If it does, display an alert message and return without adding the new image
      toast.error("You cannot add more than 4 images.");
      return;
    }

    setProductPhotos((prevPhotos?: any) => {
      if (prevPhotos === undefined) {
        return [newImage];
      } else {
        return [...prevPhotos, newImage];
      }
    });
  };
  return (
    <div>
      <Modal
        open={open}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box sx={style}>
          <div className={Style.box}>
            <div className={Style.head}>
              <h3>Update Product Image ( Max 4 Images )</h3>
              <GrFormClose onClick={handleClose} className={Style.cross} />
            </div>
            <div className={Style.body}>
              <div className={Style.video_box}>
                <div
                  style={{
                    height: "80%",
                    backgroundColor: "#F5F5F5",
                    width: "100%",
                  }}
                >
                  <ReactPlayer
                    width='100%'
                    height='100%'
                    url='<video_url>'
                  />
                </div>
                <p>Remove background</p>
              </div>
              <div className={Style.upload_box}>
                <div
                  style={{
                    height: "70%",
                    backgroundColor: "#bdbdbd",
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                    imageUploadRef.current?.click();
                  }}
                >
                  {" "}
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <input
                      ref={imageUploadRef}
                      id='files'
                      style={{ display: "none" }}
                      type='file'
                      accept='image/jpeg, image/png, image/gif'
                      onChange={(e: any) => handleImageChange(e)}
                    />
                    <BsFillCameraFill style={{ fontSize: "2rem" }} />
                    <h3>Add Image</h3>
                  </div>
                </div>
                <div className={Style.image_container}>
                  {productPhotos?.length ? (
                    productPhotos?.map((photo: any) => {
                      return (
                        <div className={Style.image_box}>
                          <GrFormClose
                            onClick={(e) => {
                              e.stopPropagation();
                              handleDeleteImage(photo);
                            }}
                            className={Style.image_cross}
                          />
                          <img
                            onClick={(e) => {
                              e.stopPropagation();
                              window.open(
                                photo?.image || photo?.thumb,
                                "_blank"
                              );
                            }}
                            width='65px'
                            height='65px'
                            key={photo?.thumb}
                            src={photo?.thumb}
                            alt='Item'
                          />
                        </div>
                      );
                    })
                  ) : (
                    <>
                      {dummyImageProduct.map((item: any) => (
                        <BsFillImageFill
                          className={Style.dummy_img}
                          key={item}
                        />
                      ))}
                    </>
                  )}
                </div>
              </div>
            </div>
            <div style={{ textAlign: "center", marginTop: "1rem" }}>
              <Button
                onClick={(e) => {
                  e.stopPropagation();
                  setOpen(false);
                }}
              // className={Style.primary_btn}
              >
                OK
              </Button>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
