/* eslint-disable @typescript-eslint/no-useless-constructor */
import axios from "axios";
import { HeadersHandler } from "./headers";
import { APIUrls } from "./urls";

class APIClass extends APIUrls {
  // apiInstance;
  constructor() {
    super();
  }

  private async getOptions(query: any, headers = {}) {
    if (!query) {
      query = {};
    }
    const sendHeaders = await HeadersHandler.getHeaders();
    const options = {
      params: query,
      headers: { ...sendHeaders, ...headers },
    };

    return options;
  }

  getData(url: string, query = {}, options = {}): Promise<any> {
    return new Promise(async (resolve, reject) => {
      axios
        .get(url, {
          ...(await this.getOptions(query)),
          ...options,
        })
        .then((res) => {
          HeadersHandler.handleResHeaders(res, "success"); ///there is a result of APi
          resolve(res?.data || res);
        })
        .catch((error) => {
          HeadersHandler.handleResHeaders(error?.response || error, "error");
          console.error(error);
          reject(error);
        });
    });
  }

  postData(url: string, data: any, query = {}, headers = {}): Promise<any> {
    return new Promise(async (resolve, reject) => {
      axios
        .post(url, data, {
          ...(await this.getOptions(query, headers)),
        })
        .then((res) => {
          HeadersHandler.handleResHeaders(res, "success");
          resolve(res?.data || res);
        })
        .catch((error) => {
          HeadersHandler.handleResHeaders(error?.response || error, "error");
          console.error(error);
          reject(error);
        });
    });
  }

  putData(url: string, data: any, query = {}): Promise<any> {
    return new Promise(async (resolve, reject) => {
      axios
        .put(url, data, {
          ...(await this.getOptions(query)),
        })
        .then((res) => {
          HeadersHandler.handleResHeaders(res, "success");
          resolve(res?.data || res);
        })
        .catch((error) => {
          HeadersHandler.handleResHeaders(error?.response || error, "error");
          console.error(error);
          reject(error);
        });
    });
  }

  deleteData(url: string, query = {}): Promise<any> {
    return new Promise(async (resolve, reject) => {
      axios
        .delete(url, {
          ...(await this.getOptions(query)),
        })
        .then((res) => {
          HeadersHandler.handleResHeaders(res, "success");
          resolve(res?.data || res);
        })
        .catch((error) => {
          HeadersHandler.handleResHeaders(error?.response || error, "error");
          console.error(error);
          reject(error);
        });
    });
  }
}

export const API = new APIClass();

// export {}
