import type { AxiosResponse } from "axios";
import axios from "axios";
import { AuthStore } from "../contexts/auth-context";
import { HeaderStore } from "../contexts/header-context";
import { environment } from "../environments/environment";
import { APIUrls } from "./urls";

import { API } from "./api";

const setUserDataAsync = async (user: string) => {
  try {
    localStorage.setItem("user", user);
  } catch (err) {}
};

const RESPONSE_HEADERS = [
  "MAINTENANCE",
  "VERSION_EXPIRED",
  "UPDATE_AVAILABLE",
  "NEW_VERSION",
  "NEW_VERSION_DESC",
  "USER_UPDATED_AT_REQUIRED",
  "RENEW_USER",
  "SESSION_EXPIRED",
  "FORBIDDEN",
];

const maintenanceTTL = 30 * 1000; // 30 seconds
const updateTTL = 2 * 60 * 60 * 1000; // 2 hours

class HeadersHandlerClass extends APIUrls {
  mainteananceInterval: any;

  async getHeaders(): Promise<{ [name: string]: string }> {
    const headers: any = {
      APP_NAME: environment.appName,
      APP_VERSION: environment.version,
      "Cache-Control": "no-cache, no-store, must-revalidate",
      // Pragma: 'no-cache'
      // Expires: "0",
    };
    let token = null;
    try {
      token = localStorage.getItem("accessToken");
    } catch (error) {
      console.error(error);
    }

    if (token) {
      ///we will send Bearer and token in Api
      headers.Authorization = "Bearer " + token;
    }

    let userString = null;
    try {
      userString = localStorage.getItem("user");
    } catch (error) {
      console.error(error);
    }

    if (userString) {
      try {
        const user = JSON.parse(userString);
        if (user && user.updatedAt) {
          headers.USER_UPDATED_AT = user.updatedAt;
        }
      } catch (error) {
        console.error("Cannot parse user data : ", error);
      }
    }
    return headers;
  }

  checkResponseHeaders(res: AxiosResponse): any {
    const headers: any = {};
    if (res?.headers) {
      for (let header of RESPONSE_HEADERS) {
        headers[header] = res.headers[header.toLowerCase()];
      }
    }

    return headers;
  }

  handleResHeaders(res: AxiosResponse, type: "success" | "error") {
    let headers = this.checkResponseHeaders(res);
    if (headers.MAINTENANCE === "YES") {
      console.error("Under maintenance");
      this.handleMaintenance();
    } else {
      if (headers.VERSION_EXPIRED === "YES") {
        console.error("Version Expired");
        this.handleVersionExpired(headers);
      } else {
        if (headers.UPDATE_AVAILABLE === "YES") {
          console.error("New version available");
          this.handleUpdateAvailable(headers);
        }
      }
      if (headers.USER_UPDATED_AT_REQUIRED === "YES") {
        console.error("User updated at is required");

        API.getData(API.authUrl + "renew")
          .then((res) => {
            let data = res.data;

            let userObj = {
              _id: data._id,
              contact: data.contact,
              name: data.name,
              updatedAt: data.updatedAt,
            };

            AuthStore.update((auth) => {
              auth.authState = {
                ...auth.authState,
                ...userObj,
              };

              setUserDataAsync(JSON.stringify(userObj));
            });
          })
          .catch((err) => {
            console.error(err);
          });

        AuthStore.update((Auth) => {
          Auth.authState = "renew";
        });
      }
      if (headers.RENEW_USER === "YES") {
        console.error("Renew User");

        API.getData(API.authUrl + "renew")
          .then((res) => {
            let data = res.data;

            let userObj = {
              _id: data._id,
              contact: data.contact,
              name: data.name,
              updatedAt: data.updatedAt,
            };

            AuthStore.update((auth) => {
              auth.authState = {
                ...auth.authState,
                ...userObj,
              };

              setUserDataAsync(JSON.stringify(userObj));
            });
          })
          .catch((err) => {
            console.error(err);
          });

        AuthStore.update((Auth) => {
          Auth.authState = "renew";
        });
      }
      if (headers.SESSION_EXPIRED === "YES") {
        // Toast.show({
        // 	type: 'info',
        // 	position: 'bottom',
        // 	text1: 'Session expired, login again'
        // });
      }
      if (headers.FORBIDDEN === "YES") {
        console.error(
          "You are not allowed (forbidden) to access this feature."
        );

        // Toast.show({
        // 	type: 'error',
        // 	position: 'bottom',
        // 	text1: 'Permission denied'
        // });
      }
    }
  }

  handleMaintenance() {
    HeaderStore.update((header) => {
      header.maintenance = true;
    });
    if (!this.mainteananceInterval) {
      this.mainteananceInterval = setInterval(async () => {
        let res: AxiosResponse;
        try {
          res = await axios.get(this.maintenanceUrl, {
            headers: await this.getHeaders(),
          });
        } catch (error) {
          console.error("Maintenance Check Error : ", error);
          res = await axios.get(this.maintenanceUrl, {});
        }

        let headers = res ? this.checkResponseHeaders(res) : null;
        if (headers.MAINTENANCE !== "YES") {
          this.cancelMaintenance();
          this.handleResHeaders(res, "success");
        }
      }, maintenanceTTL);
    }
  }

  cancelMaintenance() {
    if (this.mainteananceInterval) {
      try {
        clearInterval(this.mainteananceInterval);
        this.mainteananceInterval = null;
      } catch (error) {
        console.error("Clear Maintenance Error : ", error);
      }
    }
    HeaderStore.update((header) => {
      header.maintenance = false;
      header.maintenanceCompleted = true;
    });
  }

  handleVersionExpired(headers: any) {
    HeaderStore.update((s) => {
      s.versionExpired = true;
      s.updateAvailble = false;
      s.newVersion.version = headers.NEW_VERSION || null;
      s.newVersion.description = headers.NEW_VERSION_DESC || null;
    });
  }

  handleUpdateAvailable(headers: any) {
    HeaderStore.update((s) => {
      s.updateAvailble = true;
      s.versionExpired = false;
      s.newVersion.version = headers.NEW_VERSION || null;
      s.newVersion.description = headers.NEW_VERSION_DESC || null;
    });
  }
}

export const HeadersHandler = new HeadersHandlerClass();
