import { atom } from "recoil";

export const productAtom = atom({
  key: "productAtom",
  default: {},
});

export const showProductAddModal = atom({
  key: "showProductAddModal",
  default: false,
});

export const productsList = atom({
  key: "productsList",
  default: [],
});
export const searchProductsList = atom({
  key: "searchProductsList",
  default: [],
});

export const productsLoading = atom({
  key: "productsLoading",
  default: false,
});
