import { SvgIcon, SvgIconProps } from "@mui/material";
import PieChartIcon from '@mui/icons-material/PieChart';
import React from "react";
import Stats from './stats.png'

const Products = (props: SvgIconProps) => {
  return (
    <SvgIcon viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
      {/* <img src={Stats} alt="" /> */}
      <PieChartIcon />
    </SvgIcon>
  );
};

export default Products;
