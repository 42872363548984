import CancelIcon from "@mui/icons-material/Cancel";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { Box, Card, styled } from "@mui/material";
import Chip from "@mui/material/Chip";
import { makeStyles } from "@mui/styles";
import FlexBox from "components/FlexBox";
import NotificationSearch from "pages/dashboards/NotificationSearch";
import React, { FC, useState } from "react";
import OrderStatus from "./OrderStatus";

const StyledFlexBox = styled(FlexBox)(({ theme }) => ({
  justifyContent: "space-between",
  alignItems: "center",
  flexWrap: "wrap",
  marginBottom: 20,
  [theme.breakpoints.down(500)]: {
    width: "100%",
    "& .MuiInputBase-root": { maxWidth: "100%" },
    "& .MuiButton-root": {
      width: "100%",
      marginTop: 15,
    },
  },
}));

// styled components
const StyledCard = styled(Card)(() => ({
  position: "relative",
  borderTopLeftRadius: "0",
  borderTopRightRadius: 0,
}));

const useStyles = makeStyles({
  chips: {
    width: "6rem",
  },
});

const OrderScreen: FC = () => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const [notify, setNotify] = useState(false);
  const handleClick = () => {
    if (notify) {
      setNotify(false);
    } else {
      setNotify(true);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const notifier = {
    display: notify ? "block" : "none",
    position: "absolute",
    top: "6rem",
    left: "64rem",
    zIndex: "1",
    backgroundColor: "white",
    borderRadius: "20px",
  } as React.CSSProperties;

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const classes = useStyles();

  const [value, setValue] = useState("");
  const [allOrders, setAllOrders] = useState("close");
  // const classes = useStyles({ value: value === value });

  let icon = <ChevronLeftIcon />;

  const headingDisplay = {
    display: "flex",
    justifyContent: "space-between",
    alignContent: "center",
  };

  return (
    <div>
      <Box pt={2} pb={4} pl={8}>
        <div style={headingDisplay}>
          <h1>Manage Orders</h1>
          <div style={headingDisplay}>
            <NotificationSearch />
          </div>
        </div>

        <Box
          sx={{
            borderRadius: "14px",
            color: "black",
            width: "27.7%",
            marginLeft: "70%",
            backgroundColor: "#F2F2F2;",
            // border:'.2px solid #F2F2F2',
            display: "flex",
            justifyContent: "space-evenly",
            // padding:'.8%',
            marginBottom: "1%",
          }}
        >
          {/* <Chip
            className={classes.chips}
            style={{ fontWeight: "bolder" }}
            label='Collection'
            color={value === "All" ? "warning" : "default"}
            onClick={() => setValue("Collect Money")}
          /> */}
          {/* <Chip
            className={classes.chips}
            style={{ fontWeight: "bolder" }}
            label='All'
            color={value === "All" ? "warning" : "default"}
            onClick={() => setValue("All")}
          />
          <Chip
            className={classes.chips}
            style={{ fontWeight: "bolder" }}
            label='Self'
            color={value === "Self" ? "warning" : "default"}
            sx={{ borderColor: "#3366FF" }}
            onClick={() => setValue("Self")}
          />
          <Chip
            className={classes.chips}
            style={{ fontWeight: "bolder" }}
            label='Platform'
            color={value === "Quickfox" ? "warning" : "default"}
            onClick={() => setValue("Quickfox")}
          /> */}
        </Box>

        <StyledFlexBox>
          {allOrders === "open" && (
            <Chip
              label='Back'
              icon={icon}
              onClick={() => setAllOrders("back")}
            />
          )}
        </StyledFlexBox>
        <StyledCard>
          <OrderStatus
            allOrders={allOrders}
            setAllOrders={setAllOrders}
            search={value}
          />
        </StyledCard>
      </Box>

      <div style={notifier}>
        <CancelIcon
          sx={{ marginLeft: "90%", marginTop: "1%", height: "15px" }}
          onClick={() => setNotify(false)}
        />
      </div>
    </div>
  );
};

export default OrderScreen;
