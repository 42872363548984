import { Button } from "@mui/material";
import { useState } from "react";
import { useRecoilState, useSetRecoilState } from "recoil";
import { API } from "service/api";
import { selectCatID, selectCatLevel } from "store/products/category";
import { productAtom, showProductAddModal } from "store/products/products";
import TArrow from "../../../assets/sound/Arrowse_2.png";
import DArrow from "../../../assets/sound/DArrow.png";
import OArrow from "../../../assets/sound/chevronflat_106005.png";
import CategoryDisable from "../CategoryDisable/CategoryDisable";
import style from "./Category.module.css";
export default function Category({
  category,
  setCategory,
  getSellerCategoryTree,
  getAllProducts,
  setSelectdCat,
  setPage,
  setSelectdCatLevel,
  setPagination,
}: any) {
  const [disable, setDisable] = useState(false);
  const [l2categoryName, setL2CategoryName] = useState("");
  const [l2Hover, setL2Hover] = useState(false);
  const setShowProductModal = useSetRecoilState(showProductAddModal);
  const setEditProductData = useSetRecoilState(productAtom);
  const setCatLevel = useSetRecoilState(selectCatLevel);
  const [catId, setCatId] = useRecoilState(selectCatID);

  function handleShowInputAtL1(item: any) {
    item.expand = false;
    setCategory([...category]);
    setDisable(true);
  }
  function handleShowInputAtL2(item: any) {
    item.expand = true;
    setCategory([...category]);
    setDisable(true);
  }
  function handleHideInputAtL1(item: any) {
    item.expand = true;
    setCategory([...category]);
    setDisable(false);
  }
  function handleHideInputAtL2(item: any) {
    item.expand = false;
    setCategory([...category]);
    setDisable(false);
  }

  function handleAddCategory(item: any) {
    const data: any = {
      level1: "",
      level2: "",
      level3: "",
      level: "",

      name: l2categoryName,
    };
    if (item.level === 1) {
      data.level1 = item._id;
      data.level = 2;
    } else if (item.level === 2) {
      data.level2 = item._id;
      data.level1 = item.level1;
      data.level = 3;
    }

    const userJSON = localStorage.getItem("user");
    const user = userJSON ? JSON.parse(userJSON) : null;

    API.postData(API.sellerUrl + "addcategory", {
      seller: user._id,
      ...data,
    })
      .then((res) => {
        setCategory([...res.data]);
        getSellerCategoryTree(user._id);
      })
      .catch((e) => {
        console.error(e);
      });

    setDisable(false);
  }

  function handleSelectCategoryToShowProds(l2Cat: any) {
    setPage(1);
    setSelectdCat(l2Cat?._id);
    setCatId(l2Cat?._id);
    setSelectdCatLevel(2);
    setCatLevel(2);
  }

  return (
    <>
      <div className={style.box}>
        {category.map((cat: any) => {
          return (
            <div key={cat?._id}>
              <div className={style.cat_container}>
                <div className={style.cat_title_container}>
                  <div className={style.cat_title}>
                    {/* <BiSolidRightArrow /> */}
                    <img src={OArrow} width='20rem' alt='' />
                    <p>{cat?.name}</p>
                  </div>
                  <div className={style.update_option}>
                    <CategoryDisable
                      key={cat?._id}
                      cat={cat}
                      currentLevel={1}
                      category={category}
                      setCategory={setCategory}
                      getSellerCategoryTree={getSellerCategoryTree}
                      setPagination={setPagination}
                    />
                  </div>
                </div>
                <div className={style.cat_option}>
                  {cat.expand ? (
                    <Button
                      disabled={disable}
                      onClick={() => handleShowInputAtL1(cat)}
                      // className={style.primary_btn}
                    >
                      Add Sub-Category
                    </Button>
                  ) : (
                    <>
                      <div className={style.addCategoryContainer}>
                        <input
                          placeholder='Add Sub-Category'
                          onChange={(e) => setL2CategoryName(e.target.value)}
                          className={style.categoryAddInput}
                        />
                        <div className={style.addCategoryBtn}>
                          <button
                            onClick={() => handleAddCategory(cat)}
                            className={style.okBtn}
                          >
                            OK
                          </button>
                          <button
                            onClick={() => handleHideInputAtL1(cat)}
                            className={style.cancelBtn}
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </>
                  )}
                </div>
                <div>
                  {cat?.sub &&
                    cat?.sub?.length > 0 &&
                    cat?.sub?.map((l2Cat: any) => {
                      return (
                        <div key={l2Cat?._id}>
                          <div
                            style={
                              l2Cat?._id === catId
                                ? { backgroundColor: "rgb(112, 112, 112, 0.2)" }
                                : { backgroundColor: "white" }
                            }
                            onClick={(e) => {
                              e.stopPropagation();
                              handleSelectCategoryToShowProds(l2Cat);
                            }}
                            className={style.cat_title_container}
                          >
                            <div className={style.l2cat_title}>
                              {/* {l2Cat?.canAddSub ? (
                                <BiSolidDownArrow />
                              ) : (
                                <MdDoubleArrow />
                              )}{" "} */}
                              <img src={DArrow} width='22rem' alt='' />
                              <p>{l2Cat?.name}</p>
                            </div>
                            <div className={style.update_option}>
                              <CategoryDisable
                                key={l2Cat?._id}
                                cat={l2Cat}
                                currentLevel={2}
                                category={category}
                                setCategory={setCategory}
                                getSellerCategoryTree={getSellerCategoryTree}
                                setPagination={setPagination}
                              />
                            </div>
                          </div>

                          <div className={style.l2Cat_option}>
                            {l2Cat?.canProductAdd ? (
                              <>
                                <Button
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    setShowProductModal(true);

                                    setEditProductData({
                                      level1: l2Cat?.level1,
                                      level2: l2Cat?._id,
                                    });
                                  }}
                                  // className={style.primary_btn}
                                >
                                  Add Product
                                </Button>
                              </>
                            ) : null}
                            {l2Cat?.canAddSub ? (
                              <div style={{ width: "100%" }}>
                                {!l2Cat?.expand ? (
                                  <Button
                                    disabled={disable}
                                    onClick={() => handleShowInputAtL2(l2Cat)}
                                    // className={style.primary_btn}
                                  >
                                    Add Sub-Category
                                  </Button>
                                ) : (
                                  <>
                                    <div className={style.addCategoryContainer}>
                                      <input
                                        placeholder='Add Sub-Category'
                                        onChange={(e) => {
                                          e.stopPropagation();
                                          setL2CategoryName(e.target.value);
                                        }}
                                        className={style.categoryAddInput}
                                      />
                                      <div className={style.addCategoryBtn}>
                                        <button
                                          onClick={(e) => {
                                            e.stopPropagation();
                                            handleAddCategory(l2Cat);
                                          }}
                                          className={style.okBtn}
                                        >
                                          OK
                                        </button>
                                        <button
                                          onClick={(e) => {
                                            e.stopPropagation();
                                            handleHideInputAtL2(l2Cat);
                                          }}
                                          className={style.cancelBtn}
                                        >
                                          Cancel
                                        </button>
                                      </div>
                                    </div>
                                  </>
                                )}
                              </div>
                            ) : null}
                          </div>

                          <div>
                            {l2Cat?.sub &&
                              l2Cat?.sub.length > 0 &&
                              l2Cat?.sub?.map((l3Cat: any) => {
                                return (
                                  <div key={l3Cat?._id}>
                                    <div
                                      style={
                                        l3Cat?._id === catId
                                          ? {
                                              backgroundColor:
                                                "rgb(112, 112, 112, 0.2)",
                                            }
                                          : { backgroundColor: "white" }
                                      }
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        setPage(1);
                                        setSelectdCat(l3Cat?._id);
                                        setCatId(l3Cat?._id);
                                        setSelectdCatLevel(3);
                                        setCatLevel(3);
                                      }}
                                      className={style.cat_title_container}
                                    >
                                      <div className={style.l3cat_title}>
                                        {/* <MdDoubleArrow /> */}
                                        <img
                                          src={TArrow}
                                          width='22rem'
                                          alt=''
                                        />
                                        <p>{l3Cat?.name}</p>
                                      </div>
                                      <div className={style.update_option}>
                                        <CategoryDisable
                                          key={l3Cat?._id}
                                          cat={l3Cat}
                                          currentLevel={3}
                                          category={category}
                                          setCategory={setCategory}
                                          getSellerCategoryTree={
                                            getSellerCategoryTree
                                          }
                                          setPagination={setPagination}
                                        />
                                      </div>
                                    </div>

                                    <div className={style.l3cat_option}>
                                      {l3Cat?.canProductAdd ? (
                                        <>
                                          <Button
                                            onClick={(e) => {
                                              e.stopPropagation();
                                              setShowProductModal(true);

                                              setEditProductData({
                                                level1: l3Cat?.level1,
                                                level2: l3Cat?.level2,
                                                level3: l3Cat?._id,
                                              });
                                            }}
                                            // className={style.primary_btn}
                                          >
                                            Add Product
                                          </Button>
                                        </>
                                      ) : null}
                                    </div>
                                  </div>
                                );
                              })}
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
}
